import React from "react";
import { Heading, Button } from "@dtpk-cc/components";
import AddIcon from "../../../../assets/images/icons/pencil-selling-tool/navigation/step-header/add.svg";
import SubtractIcon from "../../../../assets/images/icons/pencil-selling-tool/navigation/step-header/subtract.svg";

import * as styles from "./amount-picker.module.scss";

type AmountPickerProps = {
  onSubtractClick: () => void;
  onAddClick: () => void;
  value: number;
  customClass?: string;
  minusAriaLabel?: string;
  plusAriaLabel?: string;
  disabledPlus?: boolean;
};

const AmountPicker = ({
  customClass = "",
  onSubtractClick,
  onAddClick,
  value,
  minusAriaLabel = "Menge verringern",
  plusAriaLabel = "Menge erhöhen",
  disabledPlus = false,
}: AmountPickerProps) => (
  <div
    className={`${styles.amountPicker} ${customClass}`}
  >
    <Button
      onClick={onSubtractClick}
      tabIndex={0}
      variants={Button.Variant.bare}
      customClass={styles.iconWrapper}
      disabled={value === 1}
      aria-label={minusAriaLabel}
    >
      <SubtractIcon className={styles.icon} />
    </Button>
    <Heading
      customClass={styles.value}
      tag="h3"
      variants={[Heading.Variant.tertiary, Heading.Variant.display]}
    >
      {value}
    </Heading>
    <Button
      onClick={onAddClick}
      tabIndex={0}
      variants={Button.Variant.bare}
      customClass={styles.iconWrapper}
      aria-label={plusAriaLabel}
      disabled={disabledPlus}
    >
      <AddIcon className={styles.icon} />
    </Button>
  </div>
);

export default AmountPicker;
