import type {
  IAddonResponse,
  IBenefitResponse,
  ICardResponse,
  IDeviceResponse,
  IProductBase,
  IPromotionResponse,
  ITariffResponse,
  LandlineResponse,
  MobileResponse,
  ProductsResponse,
  StreamingAnalysisResponse,
} from "core/repositories/ProductsRepository/ResponseInterface";
import { CustomPromotionFetchResponse } from "core/repositories/ProductsRepository/ResponseInterface";
import type { ITariff } from "core/entities/Product/Tariff/ITariff";
import type { IProduct } from "core/entities/Product/IProduct";
import { Group, PortfolioKeys } from "core/entities/Product/IProduct";
import type { IDevice } from "core/entities/Product/Device/IDevice";
import type { ICard } from "core/entities/Product/Card/ICard";
import type { IOption } from "core/entities/Product/Option/IOption";
import { CalculatorProductsResponse } from "core/repositories/ProductsRepository/CalculatorResponseInterface";
import { IStreamingService } from "../entities/Product/IStreamingService";
import { IStreamingVariant } from "../entities/Product/IStreamingVariant";
import { ICustomPromotionSections } from "../entities/PencilSelling/ICustomPromotion";
import { mapCustomPromotionFromRequest } from "../../helpers/MapCustomPromotion";
import { PromotionPriceType } from "../entities/Product/IDiscount";

export interface IProductsSummary {
  landline: {
    tariffs: ITariff[];
    devices: IDevice[];
    options: IOption[];
  };
  mobile: {
    tariffs: ITariff[];
    // | ITariff[] is applied because in BK application we treat Secondary Cards as Tariffs
    cards: ICard[] | ITariff[];
    options: IOption[];
  };
  streamingServices: IStreamingService[];
  streamingVariants: IStreamingVariant[];
}

export interface ICalculatorProductsSummary {
  tariffs: ITariff[];
  cards: ICard[];
}

export interface ProductsMapperConstructorArgs {
  productResponse: ProductsResponse;
  calculatorResponse: CalculatorProductsResponse;
  streamingAnalysisResponse: StreamingAnalysisResponse;
  customPromotionsResponse: CustomPromotionFetchResponse;
}

export const CUSTOM_PROMOTIONS_RESPONSE_DEFAULT = {
  custom_promotions: {
    landline: [],
    mobile: [],
  },
};

export class ProductsMapper {
  protected productResponse: ProductsResponse;

  protected calculatorResponse: CalculatorProductsResponse;

  protected streamingAnalysisResponse: StreamingAnalysisResponse;

  protected customPromotionsResponse: CustomPromotionFetchResponse;

  constructor({
    productResponse = null,
    calculatorResponse = null,
    streamingAnalysisResponse = null,
    customPromotionsResponse = CUSTOM_PROMOTIONS_RESPONSE_DEFAULT,
  }: Partial<ProductsMapperConstructorArgs>) {
    this.productResponse = productResponse;
    this.calculatorResponse = calculatorResponse;
    this.streamingAnalysisResponse = streamingAnalysisResponse;
    this.customPromotionsResponse = customPromotionsResponse;
  }

  protected static mapProductBase(product: IProductBase): IProduct {
    return {
      name: product.name,
      nameAlternative: product?.name_alternative || null,
      key: product.key,
      price: {
        monthly: product.pricing.monthly ?? null,
        once: product.pricing.once ?? null,
        provision: product.pricing.provision ?? null,
        suffix: product.pricing.suffix ?? null,
        originalPrice: product.pricing.original_price ?? null,
        provisionPriceBeforeFrameworkDiscount:
          product.pricing?.provision_price_before_framework_discount ?? null,
        monthlyPriceBeforeFrameworkDiscount:
          product.pricing?.monthly_price_before_framework_discount ?? null,
        gen3Discount: null,
        frameworkDiscountPercentageValue:
          product.pricing.framework_discount_percentage ?? null,
      },
      additionalDescription: product.description,
      description: product.product_description || {
        summary: "",
        text: "",
      },
      valency: product.valency,
      contractPeriod: product.contract_period || 1,
      promotions: [
        ...product.promotions.map((promotion: IPromotionResponse) => ({
          key: promotion.key,
          name: promotion.name,
          discount: {
            kind: promotion.pricing.kind,
            value: promotion.pricing.value,
            interval: promotion.pricing.quantity,
            description: promotion.pricing.notes,
            from: promotion.pricing.from,
            to: promotion.pricing.to,
          },
          conditions: promotion.conditions,
          description: promotion.description,
          additionalDescription: promotion.additional_description,
          concerned: promotion.concerned,
          totalPriceDescription: promotion?.total_price_description || null,
          summaryDescription: promotion.summary || {
            summary: "",
            text: "",
          },
          applyBeforeFrameworkContract:
            promotion?.apply_before_framework_contract ?? false,
        })),
      ],
    };
  }

  protected static mapTariff(product: IProductBase): ITariff {
    const tariff = product as ITariffResponse;

    return {
      ...ProductsMapper.mapProductBase(tariff),
      type: Group.tariff,
      tariffWorld: {
        key: tariff.tariff_world.key,
        name: tariff.tariff_world.name,
        valency: tariff.tariff_world.valency,
        links: {
          young: tariff.tariff_world.link_young,
          default: tariff.tariff_world.link_default,
          link_title: tariff.tariff_world.link_title,
        },
        limit: tariff.tariff_world.limit,
      },
      streamingServices: tariff?.streaming_services || [],
      expansionArea: tariff.expansion_area,
      level: tariff.level,
      size: tariff.sizing,
      displaySize: tariff?.sizing_alternative
        ? tariff?.sizing_alternative
        : tariff.sizing,
      availableForBusinessCases: tariff.available_for_business_cases || [],
      dataVolume: tariff.data_volume,
      dataVolumeMagentaOne: tariff.data_volume_one,
      dataVolumeExtra: tariff?.young_streaming?.data_volume || "",
      relatedAddons: tariff?.young_streaming?.addons || [],
      speed: tariff.speed,
      speedFiber: tariff.speed_fiber,
      isFiber: tariff.fiber,
      benefits: tariff.benefits
        ? tariff.benefits.map((benefit: IBenefitResponse) => ({
            name: benefit.name,
            key: benefit.key,
            // Filter array from possible 'null' items
            options: [
              benefit.discount,
              benefit.data_volume,
              benefit.flatrate,
              benefit.happy_hour,
              benefit.landline_notes,
              benefit.mobile_notes,
            ].filter((item) => !!item),
          }))
        : [],
      isYoung: tariff.young,
      isVario: tariff.vario,
      isLegacy: !!tariff.legacy,
      generation: tariff.generation,
      // TODO remove after PC redesign MVP
      extensions: [],
      // extensions: tariff.extensions
      //   ? tariff.extensions.map((extension) => ({
      //       key: extension.key,
      //       name: extension.name,
      //       productDescription: {
      //         description: extension?.description || "",
      //         summary: extension?.product_description?.summary || "",
      //         text: extension?.product_description?.text || "",
      //       },
      //       price: {
      //         monthly: extension.pricing.gross?.monthly || null,
      //         once: extension.pricing.gross?.once || null,
      //         suffix: extension.pricing.gross?.suffix || null,
      //       },
      //     }))
      //   : [],
    };
  }

  protected static mapCard(product: IProductBase): ICard {
    const card = product as ICardResponse;

    return {
      ...ProductsMapper.mapProductBase(card),
      type: Group.card,
      availableFor: card.available_for || [],
      tariffWorld: card.tariff_world,
      level: card.level,
      limit: card.tariff_world.limit, // this limit is for magenta_calculator
      size: card.sizing,
      dataVolume: card.data_volume,
      dataVolumeMagentaOne: card.data_volume_one,
      isLegacy: !!card.legacy,
      generation: card.generation,
      inheritingCard: card.inheriting_card,
      hasMultisim: card.has_multisim,
    };
  }

  protected static mapDevice(product: IProductBase): IDevice {
    const device = product as IDeviceResponse;
    return {
      ...ProductsMapper.mapProductBase(device),
      type: Group.device,
      availableFor: device.available_for || [],
      category: device.category,
      subcategory: device.subcategory,
      productGroup: {
        key: device.product_variant?.key || device.key,
        name: device.product_variant?.name || device.name,
      },
      egsp: device.egsp,
      hnpName: device.hnp_name,
      description: device.product_description,
    };
  }

  protected static mapAddon(product: IProductBase): IOption {
    const addon = product as IAddonResponse;
    return {
      ...ProductsMapper.mapProductBase(addon),
      type: Group.addon,
      availableFor: addon.available_for || [],
      additionalDescription: addon.description,
      productGroup: {
        key: addon.product_variant?.key || addon.key,
        name: addon.product_variant?.name || addon.name,
      },
      category: addon.category,
      subcategory: addon.subcategory,
    };
  }

  protected get mappedStreamingServices(): IStreamingService[] {
    return this.streamingAnalysisResponse
      ? Object.values(
          this.streamingAnalysisResponse.services.streaming_services
        ).map((streamingService) => ({
          name: streamingService.name,
          key: streamingService.key,
          productKey: streamingService.product_key,
          active: false,
          price: {
            monthly: streamingService.pricing.monthly,
          },
        }))
      : [];
  }

  protected get mappedStreamingVariants(): IStreamingVariant[] {
    return this.streamingAnalysisResponse
      ? Object.values(
          this.streamingAnalysisResponse.services.streaming_variants
        ).map((streamingVariant) => ({
          name: streamingVariant.name,
          key: streamingVariant.key,
          productKey: streamingVariant.product_key,
          kind: streamingVariant.kind,
          active: streamingVariant.active,
          monthly: streamingVariant.monthly,
          tariffWorld: streamingVariant.tariff_world,
          level: streamingVariant.level,
          areaOfUse: streamingVariant.area_of_use,
          streamingServices: streamingVariant.streaming_services,
        }))
      : [];
  }

  get customPromotions(): ICustomPromotionSections {
    return {
      [PortfolioKeys.LANDLINE]:
        this.customPromotionsResponse.custom_promotions.landline.map(
          (customPromotion) =>
            mapCustomPromotionFromRequest({
              ...customPromotion,
              portfolio: PortfolioKeys.LANDLINE,
            })
        ),
      [PortfolioKeys.MOBILE]:
        this.customPromotionsResponse.custom_promotions.mobile.map(
          (customPromotion) =>
            mapCustomPromotionFromRequest({
              ...customPromotion,
              portfolio: PortfolioKeys.MOBILE,
            })
        ),
    };
  }

  getSummaryCommands: Array<
    (
      source: { landline: LandlineResponse; mobile: MobileResponse },
      destination: IProductsSummary
    ) => void
  > = [
    (source, destination) => {
      destination.mobile.cards = ProductsMapper.sortAndMap<ICard>(
        source.mobile.cards,
        (product) => ProductsMapper.mapCard(product)
      );
    },
  ];

  protected static sortAndMap<T>(
    products: IProductBase[],
    mapFn: (product: IProductBase) => T
  ): T[] {
    return ProductsMapper.sortProductResponseByValency(products).map(mapFn);
  }

  get productsSummary(): IProductsSummary {
    if (!this.productResponse) return null;

    const landlineProducts = this.productResponse.products
      .landline as LandlineResponse;
    const mobileProducts = this.productResponse.products
      .mobile as MobileResponse;

    const productsSummary: IProductsSummary = {
      landline: {
        tariffs: [],
        devices: [],
        options: [],
      },
      mobile: {
        tariffs: [],
        cards: [],
        options: [],
      },
      streamingServices: this.mappedStreamingServices,
      streamingVariants: this.mappedStreamingVariants,
    };

    productsSummary.landline.tariffs = ProductsMapper.sortAndMap(
      landlineProducts.tariffs,
      (product) => ProductsMapper.mapTariff(product)
    );
    productsSummary.landline.devices = ProductsMapper.sortAndMap(
      landlineProducts.devices,
      (product) => ProductsMapper.mapDevice(product)
    );
    productsSummary.landline.options = ProductsMapper.sortAndMap(
      landlineProducts.addons,
      (product) => ProductsMapper.mapAddon(product)
    );
    productsSummary.mobile.tariffs = ProductsMapper.sortAndMap(
      mobileProducts.tariffs,
      (product) => ProductsMapper.mapTariff(product)
    );
    productsSummary.mobile.options = ProductsMapper.sortAndMap(
      mobileProducts.addons,
      (product) => ProductsMapper.mapAddon(product)
    );

    this.getSummaryCommands.forEach((command) =>
      command(
        { landline: landlineProducts, mobile: mobileProducts },
        productsSummary
      )
    );

    return productsSummary;
  }

  get calculatorProductsSummary(): ICalculatorProductsSummary {
    if (!this.calculatorResponse) return null;

    const calculatorProductsSummary: ICalculatorProductsSummary = {
      tariffs: [],
      cards: [],
    };

    const calculatorProducts = this.calculatorResponse.products.mobile;

    calculatorProductsSummary.tariffs = calculatorProducts.tariffs.map(
      (tariff) => ProductsMapper.mapTariff(tariff)
    );

    calculatorProductsSummary.cards = calculatorProducts.cards.map((card) =>
      ProductsMapper.mapCard(card)
    );

    return calculatorProductsSummary;
  }

  protected static sortProductResponseByValency(
    productArray: IProductBase[]
  ): IProductBase[] {
    const sortByValency = (array: IProductBase[]): IProductBase[] =>
      array.sort((a: IProductBase, b: IProductBase) => a.valency - b.valency);

    return sortByValency(productArray);
  }
}
