import React, { useState } from "react";
import { trackClick } from "helpers/reactTracking";
import { Button, Container, Tile, CopyText, Input } from "@dtpk-cc/components";
import "./custom-promotion-tile.scss";
import { onlyNumber } from "helpers/FireFoxFix";
import { convertPriceStringToNumber } from "../../helpers/NumberHelpers";

const CustomPromotionTile = ({
  hint,
  customPromotion,
  setCustomPromotion,
  trackingContext,
  addCustomPromotion,
}) => {
  const [promotionValidState, setPromotionValidState] = useState(
    (() => {
      const promotionState = {};
      Object.keys(customPromotion).forEach((key) => {
        promotionState[key] = true;
      });
      return promotionState;
    })()
  );

  const validatePromotion = () => {
    let promotionIsValid;
    if (!customPromotion.name) {
      promotionIsValid = false;
      setPromotionValidState((prev) => ({
        ...prev,
        name: false,
      }));
    } else {
      promotionIsValid = true;
      setPromotionValidState((prev) => ({
        ...prev,
        name: true,
      }));
    }
    return promotionIsValid;
  };

  const handleCustomPromotionInputChange = (event, key) => {
    if (key === "name") {
      const { value } = event.target;
      setCustomPromotion((prev) => ({ ...prev, [key]: value }));
    } else {
      const numberValue = convertPriceStringToNumber(event?.target?.value);
      let computedValue = null;
      computedValue = !numberValue || numberValue < 0 ? 0 : numberValue;

      setCustomPromotion((prev) => ({ ...prev, [key]: computedValue }));
    }
  };
  return (
    <Tile variant="shadow" customClass="custom-promotion-tile__promotion">
      <Tile.Content>
        <Input
          isValid={promotionValidState.name}
          value={customPromotion.name}
          customClass="custom-promotion-tile__input"
          onChange={(event) => handleCustomPromotionInputChange(event, "name")}
          onClick={() => trackClick("aktionsname", trackingContext)}
        >
          Aktionsname
        </Input>

        <Container customClass="custom-promotion-tile__input-wrapper">
          <Input
            customClass="custom-promotion-tile__input-w-30"
            // value has to be a string otherwise LPG Styling bug
            value={customPromotion.interval.toString()}
            onChange={(event) => {
              handleCustomPromotionInputChange(event, "interval");
            }}
            onClick={() => trackClick("aktionsdauer", trackingContext)}
            onKeyPress={(e) => onlyNumber(e)}
          >
            Dauer
          </Input>
          <CopyText tag="span" customClass="custom-promotion-tile__close-btn">
            x
          </CopyText>
          <Input
            // value has to be a string otherwise LPG Styling bug
            value={customPromotion.price.toString().replace(".", ",")}
            onChange={(event) => {
              handleCustomPromotionInputChange(event, "price");
            }}
            onClick={() => trackClick("aktionspreis", trackingContext)}
            onKeyPress={(e) => onlyNumber(e)}
          >
            Preis
          </Input>
        </Container>

        {hint && (
          <CopyText customClass="custom-promotion-tile__hint">{hint}</CopyText>
        )}
        <Button
          onClick={() => {
            const formIsValid = validatePromotion();
            if (formIsValid) {
              addCustomPromotion();
            }
            trackClick("aktion-hinzufügen", trackingContext);
          }}
        >
          Aktion hinzufügen
        </Button>
      </Tile.Content>
    </Tile>
  );
};

export default CustomPromotionTile;
