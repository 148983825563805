import { LatLng, LeafletMouseEvent } from "leaflet";
import { useEffect, useMemo, useState } from "react";
import debounce from "lodash.debounce";
import { mapApi } from "../api/mapApi";
import { AddressDetails } from "../api/types";

export const useAddressSearch = (
  setLocationPoint: (point: LatLng | null) => void
) => {
  const [input, setInput] = useState<string>("");
  const [suggestions, setSuggestions] = useState<AddressDetails[]>([]);

  const handleInputChange = (value: string) => {
    setInput(value);
    if (value === "") {
      setLocationPoint(null);
    }
  };

  const handleLocationClick = (e: LeafletMouseEvent) => {
    setLocationPoint(e.latlng);
    mapApi
      .getAddress(e.latlng)
      .then((addressDetails) => setInput(addressDetails?.address || ""));
  };

  const handleAddressSelect = (addressDetails: AddressDetails) => {
    setLocationPoint(addressDetails.point);
    setInput(addressDetails.address);
    setSuggestions([]);
  };

  const debouncedGetAddressSuggestions = useMemo(
    () =>
      debounce((inputValue: string) => {
        mapApi
          .getAddressSuggestions(inputValue)
          .then((addressSuggestions) => setSuggestions(addressSuggestions))
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.error(err);
          });
      }, 500),
    []
  );

  const clearInput = () => {
    setInput("");
    setLocationPoint(null);
  };

  useEffect(() => {
    if (input?.length > 3) {
      debouncedGetAddressSuggestions(input);
    } else {
      setSuggestions([]);
    }

    return () => {
      debouncedGetAddressSuggestions.cancel();
    };
  }, [input, debouncedGetAddressSuggestions]);

  return {
    input,
    suggestions,
    handleInputChange,
    handleLocationClick,
    handleAddressSelect,
    clearInput,
  };
};
