import React from "react";
import { AutoSuggestedInput, Container } from "@dtpk-cc/components";
import { useAddressSearch } from "views/Map/hooks/useAddressSearch";
import { useMapEvents } from "react-leaflet";
import { LatLng } from "leaflet";
import { AddressDetails } from "views/Map/api/types";
import { ActionCloseSelected } from "@dtpk-cc/components/dist/icons";
import * as styles from "./map-sidebar-address-input.module.scss";

type MapSidebarAddressProps = {
  setLocationPoint: (point: LatLng | null) => void;
  isSelected: boolean;
  selectAddressField: () => void;
  id: string;
};

const MapSidebarAddress = ({
  setLocationPoint,
  isSelected,
  selectAddressField,
  id,
}: MapSidebarAddressProps) => {
  const {
    input,
    suggestions,
    handleInputChange,
    handleLocationClick,
    handleAddressSelect,
    clearInput,
  } = useAddressSearch(setLocationPoint);

  useMapEvents({
    click: (e) => isSelected && handleLocationClick(e),
  });

  const itemToString = (element: AddressDetails) => element?.address || "";
  const displaySuggestedItem = (element: AddressDetails) => element.address;

  const inputGroupProps = {
    label: "Adresse wählen oder auf Karte klicken",
    value: input,
  };

  const dropdownGroupProps = {
    displaySuggestedItem,
    noResultText: "Keine Adressen gefunden",
    resultOverloadedText: "Zu viele Adressen gefunden",
    showSuggestionMinAmount: 4,
  };

  const technicalGroupProps = {
    itemToString,
    onInputValueChange: (changes) => {
      handleInputChange(changes.inputValue);
    },
    onSelectedItemChange: (changes) => {
      handleAddressSelect(changes.selectedItem);
    },
    inputValue: input,
  };

  return (
    <Container
      customClass={`${styles.addressInput} ${
        isSelected ? styles.isSelected : ""
      }
        ${suggestions.length === 0 ? styles.noSuggestions : ""}`}
    >
      <AutoSuggestedInput<AddressDetails>
        id={`${id}-address-input`}
        value={input}
        onFocus={selectAddressField}
        button={
          input
            ? {
                icon: ActionCloseSelected,
                label: "Adresse",
                onClick: clearInput,
              }
            : null
        }
        suggestedItems={suggestions || []}
        inputGroupProps={inputGroupProps}
        dropdownGroupProps={dropdownGroupProps}
        technicalGroupProps={technicalGroupProps}
      />
    </Container>
  );
};

export default MapSidebarAddress;
