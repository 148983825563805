import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "stores/BusinessClient";
import PageWrapper from "elements/new-design/PageWrapper";
import PageInner from "elements/new-design/PageWrapper/PageInner";
import StepActionHeader from "components/new-design/StepHeader/StepActionHeader";
import { ICartConfigurationType } from "core/entities/PencilSelling/Configurations/IConfigurations";
import { useStepActionHeaderNavMap } from "hooks/PencilSelling/useStepActionHeaderNavMap";
import SecondaryCardsSelection from "./SecondaryCardsSelection";
import MobileTariffAddons from "../MobileTariff/MobileTariffAddons";
import { useRemoveConfiguration } from "../../../../hooks/PencilSelling/useRemoveConfiguration";
import { useGetActiveConfigurationDefaultName } from "../../../../hooks/PencilSelling/useGetActiveConfigurationDefaultName";

const TRACKING_CONTEXT = "zusatzkarten-geschaeftskunden";

type SecondaryCardsProps = {
  displayConfigurationFlyout: (flyoutKey: string) => void;
};

const SecondaryCards = ({
  displayConfigurationFlyout,
}: SecondaryCardsProps) => {
  const { configurationsStore, offerStore, productsStore } =
    useStores();
  const { removeConfigurationHandler } = useRemoveConfiguration(
    configurationsStore,
    offerStore.getBaseOffer()
  );
  const activeConfiguration = configurationsStore.getActiveConfiguration();
  const { activeTabIndex, defaultNavMap } = useStepActionHeaderNavMap(
    TRACKING_CONTEXT,
    configurationsStore
  );
  const { activeConfigurationName } = useGetActiveConfigurationDefaultName(
    ICartConfigurationType.CARD,
    configurationsStore
  );
  const navDisabledMap = [
    { disabled: false },
    {
      disabled: !configurationsStore
        .getActiveConfigurationChildren()
        .getMobileTariff(),
    },
  ];

  const businessCase = offerStore
    .getBaseOffer()
    .getBusinessCase(ICartConfigurationType.CARD);

  const navMap = defaultNavMap.map((navItem, index) => ({
    ...navItem,
    ...navDisabledMap[index],
  }));

  const recalculateBusinessCardsGen3Amount = () => {
    const configsAmount =
      offerStore.getBusinessCards3GenAmountFromConfigurations();
    offerStore.setBusinessCardsGen3Amount({ configurations: configsAmount });
    const cardTariffConfigurations =
      offerStore.getBusinessCards3GenConfigurations();

    if (cardTariffConfigurations.length) {
      cardTariffConfigurations.forEach((configuration) => {
        const currentCardTariff = configuration.children.getMobileTariff();
        const baseCardTariff =
          currentCardTariff &&
          (productsStore.tariffCards.find(
            (baseCardProduct) => baseCardProduct.key === currentCardTariff.key
          ) ||
            null);
        const gen3DiscountLimitExist =
          currentCardTariff &&
          typeof currentCardTariff?.price?.gen3Discount?.required_quantity ===
            "number";
        if (
          gen3DiscountLimitExist &&
          offerStore.businessCards3GenAmountTotal >=
            currentCardTariff?.price?.gen3Discount?.required_quantity
        ) {
          configuration.children.replace(currentCardTariff.key, {
            ...currentCardTariff,
            price: {
              ...currentCardTariff.price,
              monthly: baseCardTariff.price?.gen3Discount.value,
            },
          });
        } else if (
          gen3DiscountLimitExist &&
          offerStore.businessCards3GenAmountTotal <
            currentCardTariff?.price?.gen3Discount?.required_quantity
        ) {
          configuration.children.replace(currentCardTariff.key, {
            ...currentCardTariff,
            price: {
              ...currentCardTariff.price,
              monthly: baseCardTariff.price.monthly,
            },
          });
        }
      });
    }
  };

  const limit =
    configurationsStore.getActiveConfigurationChildren().getMobileTariff()
      ?.tariffWorld?.limit || null;
  const amount = configurationsStore.getActiveConfigurationAmount();

  const pickerPlusDisabled = !limit ? false : amount >= limit;

  useEffect(() => {
    if (limit && amount > limit) {
      configurationsStore.setActiveConfigurationAmount(limit);
    }
  }, [limit, amount, configurationsStore]);

  const navTabsMap = [
    <SecondaryCardsSelection
      onRecalculateCardsGen3Amount={recalculateBusinessCardsGen3Amount}
    />,
    <MobileTariffAddons
      businessCase={businessCase}
      trackingContext="extras-zusatzkarten-geschaeftskunden"
    />,
  ];

  return (
    <PageWrapper>
      <StepActionHeader
        configurationsStore={configurationsStore}
        trackingContext={TRACKING_CONTEXT}
        title={activeConfigurationName}
        subtitle="Für mehr Flexibilität beim Telefonieren und Surfen"
        navMap={navMap}
        activeTabIndex={activeTabIndex}
        amountPickerOnChange={recalculateBusinessCardsGen3Amount}
        disabledPlus={pickerPlusDisabled}
        removeConfigurationHandler={() =>
          removeConfigurationHandler({
            type: ICartConfigurationType.CARD,
            configurationKeysToDelete: [activeConfiguration.key],
          })
        }
        offerStore={offerStore.getBaseOffer()}
        displayConfigurationFlyout={displayConfigurationFlyout}
      />
      <PageInner
      >
        {navTabsMap[activeTabIndex]}
      </PageInner>
    </PageWrapper>
  );
};

export default observer(SecondaryCards);
