import React, { useState } from "react";
import IncrementalButtonGroup from "elements/IncrementalButtonGroup";
import PriceInput from "elements/PriceInput";
import PeriodInput from "elements/PeriodInput";
import { CopyText, Tile, Price } from "@dtpk-cc/components";
import { trackClick } from "helpers/reactTracking";
import {
  ActionEditDefault,
  ActionRemoveDefault,
} from "@dtpk-cc/components/dist/icons";
import { MagentaNextCard } from "core/presenter/MagentaNext/LegacyTariffPresenter";
import { observer } from "mobx-react";
import CardCategoryHeader from "./CardCategoryHeader";
import CardCategoryFooter from "./CardCategoryFooter";
import CardCategoryItemControl from "./CardCategoryItemControl";
import * as styles from "./card-category.module.scss";
import CardCategoryItemTitle from "./CardCategoryItemTitle";

type CardCategoryProps = {
  name?: string;
  cards: MagentaNextCard[];
  defaultName?: string;
  addCardBtnText?: string;
  isCustom?: boolean;
  withGB?: boolean;
  onAddCard?: () => void;
  onDeleteCard?: (card: MagentaNextCard) => void;
  isCardLimitReached?: boolean;
  onAsteriskClick?: () => void;
  onChangeCard?: (card: MagentaNextCard, section?: string) => void;
  onChangePrice?: (key: string, value: number) => void;
  onChangePeriod?: (key: string, value: number) => void;
  onDataVolumeChange?: (key: string, value: string) => void;
  onChangeName?: (key: string, value: string) => void;
  onChangeQuantity?: (key: string, quantity: number) => void;
  isMagentaOne?: boolean;
  onlyWithQuantity?: boolean;
  disabled?: boolean;
  isActiveHighlighted?: boolean;
  printRow?: boolean;
};

const CardCategory = ({
  name,
  cards,
  defaultName = "Kartenname",
  addCardBtnText = "Karte hinzufügen",
  isCustom = false,
  withGB = false,
  onAddCard,
  onDeleteCard,
  isCardLimitReached,
  onAsteriskClick,
  onChangeCard,
  onChangePrice,
  onChangePeriod,
  onDataVolumeChange,
  onChangeName,
  onChangeQuantity,
  isMagentaOne = false,
  onlyWithQuantity,
  disabled = false,
  isActiveHighlighted = false,
  printRow = false,
}: CardCategoryProps) => {
  const trackingContext = "zusatzkarte";
  const [isNameEditable, setIsNameEditable] = useState(false);
  const activeCards = cards.filter((card) =>
    onlyWithQuantity ? card.quantity > 0 : true
  );

  const getMaxQuantity = (card: MagentaNextCard) => {
    const cardQuantity = card.quantity || 0;
    const maxDefault = ("limit" in card && card.limit) || 99;
    return disabled || isCardLimitReached ? cardQuantity : maxDefault;
  };

  function getVolume(card: MagentaNextCard) {
    const volume = isMagentaOne ? card.dataVolumeMagentaOne : card.dataVolume;
    return volume === "unbegrenzt" ? "∞ GB" : volume;
  }

  const handleCardChange = (card: MagentaNextCard) => {
    if (onChangeCard) onChangeCard(card);
    else setIsNameEditable((isEditable) => !isEditable);
  };

  const rowClasses = (card: MagentaNextCard) =>
    printRow || !!card?.quantity
      ? `${styles.cardCategory} ${styles.row} ${styles.print} ${
          isActiveHighlighted ? styles.active : ""
        }`
      : `${styles.cardCategory} ${styles.row}`;

  return (
    <div
      className={`${styles.cardCategory} ${styles.container} ${
        disabled ? styles.disabled : ""
      }`}
    >
      {name && (
        <CardCategoryHeader
          headerCustomClass={`${styles.cardCategory}`}
          name={name}
          onAsteriskClick={() => {
            onAsteriskClick();
            trackClick("aktionen-asterisk", trackingContext);
          }}
        />
      )}
      <Tile
        variant="shadow"
        customClass={`${styles.cardCategory} ${styles.list}`}
      >
        {activeCards.map(
          (card) =>
            card && (
              <Tile.Content key={card.key} customClass={rowClasses(card)}>
                <div
                  className={`${styles.cardCategory} ${styles.column} ${styles.title}`}
                >
                  <CardCategoryItemTitle
                    isNameEditable={isNameEditable}
                    setIsNameEditable={setIsNameEditable}
                    cardQuantity={card.quantity}
                    cardTitle={card.name}
                    defaultName={defaultName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      onChangeName(card.key, e.target.value);
                    }}
                  />
                </div>

                <div
                  className={`${styles.cardCategory} ${styles.column} ${styles.controls}`}
                >
                  {onDeleteCard && (
                    <CardCategoryItemControl
                      customClass={`${styles.cardCategory} ${styles.edit}`}
                      onClick={() => {
                        onDeleteCard(card);
                        trackClick(`${card.name}.löschen`, trackingContext);
                      }}
                      hintText="Tarif löschen"
                      icon={ActionRemoveDefault}
                    />
                  )}

                  {(onChangeCard || onChangeName) && (
                    <CardCategoryItemControl
                      customClass={`${styles.cardCategory} ${styles.edit}`}
                      onClick={() => {
                        handleCardChange(card);
                        trackClick(`${card.name}.ändern`, trackingContext);
                      }}
                      hintText="Tarif ändern"
                      icon={ActionEditDefault}
                    />
                  )}

                  {onChangeQuantity && (
                    <IncrementalButtonGroup
                      minQuantity={0}
                      maxQuantity={getMaxQuantity(card)}
                      handleMinusClick={(quantity: number) => {
                        onChangeQuantity(card.key, quantity);
                        trackClick(`${card.key}.${quantity}`, trackingContext);
                      }}
                      handlePlusClick={(quantity: number) => {
                        onChangeQuantity(card.key, quantity);
                        trackClick(`${card.key}.${quantity}`, trackingContext);
                      }}
                      value={card.quantity || 0}
                    />
                  )}
                </div>

                {card.dataVolume && !isCustom && (
                  <div
                    className={`${styles.cardCategory} ${styles.column} ${styles.number}`}
                  >
                    <CopyText variants={CopyText.Variant.highlight}>
                      {getVolume(card)}
                    </CopyText>
                  </div>
                )}

                {onChangePeriod && (
                  <div
                    className={`${styles.cardCategory} ${styles.column} ${styles.number}`}
                  >
                    <PeriodInput
                      value={card.contractPeriod}
                      onChange={(period: number) =>
                        onChangePeriod(card.key, period)
                      }
                      onClick={() => {
                        trackClick("dauer", trackingContext);
                      }}
                    />
                  </div>
                )}

                <div
                  className={`${styles.cardCategory} ${styles.column} ${styles.number}`}
                >
                  {isCustom ? (
                    <>
                      {withGB && (
                        <>
                          <input
                            onChange={(event) => {
                              onDataVolumeChange(card.key, event.target.value);
                              trackClick("fremdkarte-gb", trackingContext);
                            }}
                            className="price-input"
                            type="number"
                            min="0"
                            value={
                              card.dataVolume ? parseFloat(card.dataVolume) : ""
                            }
                          />
                          <CopyText
                            variants={[
                              CopyText.Variant.highlight,
                              CopyText.Variant.magenta,
                            ]}
                            tag="span"
                            customClass="price-input__copy price-input__copy--mr"
                          >
                            GB
                          </CopyText>
                        </>
                      )}
                      <PriceInput
                        value={card.price?.monthly ?? 0}
                        onChange={(price: number) =>
                          onChangePrice(card.key, price)
                        }
                        suffix="€"
                        onClick={() =>
                          trackClick(
                            "mobilfunk-tarife-im-bestand-preis",
                            trackingContext
                          )
                        }
                      />
                    </>
                  ) : (
                    <Price
                      variants={[Price.Variant.brand, Price.Variant.highlight]}
                      suffix="mtl."
                      value={card.price.monthly}
                    />
                  )}
                </div>
              </Tile.Content>
            )
        )}
        {onAddCard && (
          <CardCategoryFooter
            onAddCard={onAddCard}
            addCardBtnText={addCardBtnText}
          />
        )}
      </Tile>
    </div>
  );
};

export default observer(CardCategory);
