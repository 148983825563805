import React from "react";
import ActionRemoveDefault from "@dtpk-cc/components/dist/icons/Action/Remove/Default";
import { Button, Grid, Dropdown, Price, Input } from "@dtpk-cc/components";
import { onlyNumber } from "helpers/FireFoxFix";
import { trackClick } from "helpers/reactTracking";
import { ICardDetail } from "core/entities/Product/Card/ICard";
import * as styles from "./secondary-card-section-row.module.scss";

type SecondaryCardSectionRowProps = {
  currentCardKey: string;
  removeCardHandler: (cardKey: string) => void;
  levelsNameArr: string[];
  changeLevelHandler: (targetLevel: string, currentLevel: string) => void;
  currentLevel: string;
  monthlyPrice: number;
  changeSmartPhoneDataHandle: (
    value: string | number,
    key: keyof ICardDetail
  ) => void;
  isLegacyTariffVisible: boolean;
  smartphoneName: string;
  smartphonePrice: string;
  index: number;
};

const SecondaryCardSectionRow = ({
  currentCardKey,
  removeCardHandler,
  levelsNameArr,
  changeLevelHandler,
  currentLevel,
  monthlyPrice,
  changeSmartPhoneDataHandle,
  isLegacyTariffVisible,
  smartphoneName,
  smartphonePrice,
  index,
}: SecondaryCardSectionRowProps) => (
  <Grid.Row customClass={`${styles.secondaryCardSectionRow} ${styles.row}`}>
    <Grid.Col
      customClass={`${styles.secondaryCardSectionRow} ${styles.col}`}
      m={1}
      l={1}
      xl={1}
    >
      <Button
        customClass={`${styles.secondaryCardSectionRow} ${styles.removeBtn}`}
        variants={[Button.Variant.bare]}
        onClick={() => {
          removeCardHandler(currentCardKey);
        }}
      >
        <Button.Icon icon={ActionRemoveDefault} />
      </Button>
    </Grid.Col>
    <Grid.Col
      customClass={`${styles.secondaryCardSectionRow} ${styles.col}`}
      m={9}
      l={9}
      xl={19}
    >
      <div
        className={`${styles.secondaryCardSectionRow} ${styles.inputsWrapper} ${
          isLegacyTariffVisible ? styles.comparising : ""
        }`}
      >
        <Dropdown
          customClass={`${styles.secondaryCardSectionRow} ${styles.dropdown} ${
            isLegacyTariffVisible ? styles.comparising : ""
          }`}
          label="Substufe"
          value={currentLevel}
          onSelection={(levelNameValue) => {
            changeLevelHandler(levelNameValue, currentLevel);
            trackClick(`substufe-${levelNameValue}`);
          }}
          items={levelsNameArr}
        />
        <Input
          value={smartphoneName}
          id={`phone-name-${currentCardKey}-${currentLevel}-${index}`}
          customClass={`${styles.secondaryCardSectionRow} ${styles.phoneName}`}
          onClick={() => {
            trackClick("smartphone)");
          }}
          onChange={(event) => {
            changeSmartPhoneDataHandle(event.target.value, "smartphoneName");
          }}
          type="text"
        >
          Smartphone
        </Input>
        <Input
          value={smartphonePrice}
          id={`phone-price-${currentCardKey}-${currentLevel}-${index}`}
          customClass={`${styles.secondaryCardSectionRow} ${styles.phonePrice}`}
          onChange={(event) => {
            changeSmartPhoneDataHandle(event.target.value, "smartphonePrice");
          }}
          onClick={() => {
            trackClick("kaufpreis)");
          }}
          onKeyPress={(e) => onlyNumber(e)}
          type="text"
        >
          Kaufpreis
        </Input>
      </div>
    </Grid.Col>
    <Grid.Col
      customClass={`${styles.secondaryCardSectionRow} ${styles.priceCol} ${
        isLegacyTariffVisible ? styles.comparising : ""
      }`}
      m={2}
      l={2}
      xl={4}
    >
      <Price
        variants={[Price.Variant.brand]}
        value={monthlyPrice}
        suffix={"mtl."}
      />
    </Grid.Col>
  </Grid.Row>
);

export default SecondaryCardSectionRow;
