import type { IDevice } from "core/entities/Product/Device/IDevice";
import { ICategory, IProductGroupBase } from "core/entities/Product/IProduct";
import type { IOption } from "core/entities/Product/Option/IOption";
import { ICartItemData } from "core/entities/PencilSelling/CartItem/ICartItem";
import { IPrice } from "../../entities/Product/IPrice";

export type IAddon = IDevice | IOption;

export type Category = {
  key: string;
  name: string;
  subcategories: Subcategory[];
  productGroups: ProductGroup[];
  valency: number;
};

type Subcategory = {
  key: string | null;
  name: string | null;
  productGroups: ProductGroup[];
  valency?: number;
  link?: string | null;
  link_title: string | null;
};

export interface ProductGroup extends IProductGroupBase {
  products: IAddon[];
  minPrice?: IPrice;
}

export enum SelectionTypes {
  PRODUCTS = "products",
  NONE = "none",
  PRICE = "price",
}

type PaymentTypes = "once" | "monthly";

export class AddonsPresenter {
  paymentTypes: Record<string, PaymentTypes> = {
    ONCE: "once",
    MONTHLY: "monthly",
  };

  selectionTypes: Record<keyof typeof SelectionTypes, SelectionTypes> = {
    PRODUCTS: SelectionTypes.PRODUCTS,
    NONE: SelectionTypes.NONE,
    PRICE: SelectionTypes.PRICE,
  };

  constructor(private products: IAddon[], private condition: string | null) {}

  private get categories(): ICategory[] {
    return this.products.reduce((acc, product) => {
      const categoryExists = acc.some(
        ({ key }) => product.category.key === key
      );

      const productMatchesCondition =
        this.condition && product.availableFor.length > 0
          ? product.availableFor.includes(this.condition)
          : true;

      if (!productMatchesCondition) return acc;

      const category = {
        key: product.category.key,
        name: product.category.name,
        valency: product.category.valency,
        link: product.category.link,
        link_title: product.category.link_title,
      };

      return categoryExists ? acc : [...acc, category];
    }, [] as ICategory[]);
  }

  get formattedCategories(): Category[] {
    const result = this.categories.map((category) => ({
      ...category,
      // NOTE: get all subcategories from this category
      subcategories: this.getSubcategories(category.key),
      // NOTE: get all product groups from this category
      productGroups: this.getProductGroups(category.key, false),
    })) as Category[];
    const sortedCategories = result.sort(
      (current, next) => current.valency - next.valency
    );
    const sortedCategoriesWithSubcategories = sortedCategories.map(
      (categoryGroup) => ({
        ...categoryGroup,
        subcategories: categoryGroup.subcategories.sort(
          (current, next) => current.valency - next.valency
        ),
      })
    );
    return sortedCategoriesWithSubcategories;
  }

  private getSubcategories(categoryKey: string): Subcategory[] {
    return this.products.reduce((acc, addon) => {
      const { subcategory, category } = addon;
      const addonIsInCategory = category.key === categoryKey;

      const existingSubcategory = acc.find(
        ({ key }) => key === subcategory?.key
      );

      // TODO: check if this is needed
      //   const productMatchesCondition =
      //   this.condition && addon.availableFor.length > 0
      //     ? addon.availableFor.includes(this.condition)
      //     : true;

      // if (!productMatchesCondition) return acc;

      if (
        !addonIsInCategory ||
        existingSubcategory ||
        !subcategory ||
        subcategory?.key === null
      )
        return acc;

      const productGroups = this.getProductGroups(subcategory.key, true);
      return [...acc, { ...subcategory, productGroups }];
    }, [] as Subcategory[]);
  }

  private calculateProductGroupMinPrice = (
    productGroup: ProductGroup,
    priceType: PaymentTypes
  ) => {
    const pricesArray = productGroup.products.reduce((acc, product) => {
      if (typeof product.price[priceType] === "number")
        acc.push(product.price[priceType]);
      return acc;
    }, [] as number[]);

    return pricesArray.length ? Math.min(...pricesArray) : null;
  };

  private getProductGroups(
    categoryKey: string,
    isSubcategory: boolean
  ): ProductGroup[] {
    const convertedProductGroups = this.products.reduce((acc, addon) => {
      const category = isSubcategory
        ? addon.subcategory?.key
        : addon.category.key;

      // NOTE: This checks if the addon.category is of the same category as the #categoryKey we are looking at
      const addonIsInCategory = category === categoryKey;

      // if the product does not belong to this category or category does not exist then do nothing - return acc;
      if (!addonIsInCategory || !category) return acc;
      // if the product belongs to subcategory but we are mapping category productGroup then do nothing - return acc;
      if (!isSubcategory && addon.subcategory?.key) return acc;

      /* check if we have a product that fulfills the following:
        we have a condition check if products availableFor includes the condition
      */
      const addonIsMatchCondition =
        this.condition && addon.availableFor.length > 0
          ? addon.availableFor.includes(this.condition)
          : true;

      if (!addonIsMatchCondition) return acc;

      const existingProductGroup = acc.find(
        (group) => group.key === addon.productGroup.key
      );
      if (existingProductGroup) {
        existingProductGroup.products.push(addon);
        return acc;
      }
      return [...acc, { ...addon.productGroup, products: [addon] }];
    }, [] as ProductGroup[]);

    return convertedProductGroups.map((productGroup) => ({
      ...productGroup,
      minPrice: {
        monthly: this.calculateProductGroupMinPrice(
          productGroup,
          this.paymentTypes.MONTHLY
        ),
        once: this.calculateProductGroupMinPrice(
          productGroup,
          this.paymentTypes.ONCE
        ),
      },
    }));
  }

  activeGroupItem(
    activeProducts: ICartItemData[],
    groupKey: string
  ): ICartItemData | null {
    return (
      activeProducts.find((item) => {
        const productGroup = this.getProductGroupByProduct(item.key);
        return productGroup?.key === groupKey;
      }) || null
    );
  }

  findProduct(key: string): IAddon {
    return this.products.find((addon) => addon.key === key);
  }

  getProductGroupByProduct(key: string): ProductGroup | null {
    const resolvedProduct = this.findProduct(key);

    if (!resolvedProduct) return null;
    return this.getProductGroup(resolvedProduct.productGroup.key);
  }

  getProductGroup(key: string): ProductGroup | null {
    const products = this.products.filter(
      ({ productGroup }) => productGroup.key === key
    );

    if (!products.length) return null;
    return { ...products[0].productGroup, products };
  }

  isMultiProductGroup(key: string): boolean {
    const group = this.getProductGroup(key);
    return !!(group?.products.length > 1);
  }

  getPaymentType(monthlyPrice: unknown): PaymentTypes {
    return typeof monthlyPrice === "number"
      ? this.paymentTypes.MONTHLY
      : this.paymentTypes.ONCE;
  }

  getSelectionType(groupKey: string): SelectionTypes {
    if (!this.isGroupWithSelection(groupKey)) return SelectionTypes.NONE;
    if (this.isMultiProductGroup(groupKey)) return SelectionTypes.PRODUCTS;
    return SelectionTypes.PRICE;
  }

  isProductWithSelection(key: string) {
    const resolvedProduct = this.findProduct(key);
    if (!resolvedProduct) return false;

    return this.isGroupWithSelection(resolvedProduct.productGroup?.key);
  }

  isProductWithPromotions(key: string, paymentType: string): boolean {
    const resolvedProduct = this.findProduct(key);
    if (!resolvedProduct) return false;

    const promotions = resolvedProduct.promotions?.filter((promotion) =>
      promotion.concerned ? promotion.concerned === paymentType : true
    );

    return !!promotions?.length;
  }

  isGroupWithSelection(key: string): boolean {
    /*
      All products are in product groups. If more
      than one product is inside a group its called
      a "isMultiProductGroup". If there is only one
      product it can have more than one paymentType.
      This is called "isMultiPrice".
    */
    const group = this.getProductGroup(key);
    // group.products[0].price accessing first product is relevant,
    // because it's guaranteed that 'group' will have only one product.
    // That guarantee comes from this.isMultiProductGroup(key), that will return 'true' if there will be more than one product in the group
    const { monthly, once } = group.products[0].price;
    const isMultiProductGroup = this.isMultiProductGroup(key);
    const isMultiPrice =
      typeof monthly === "number" && typeof once === "number";
    return isMultiProductGroup || isMultiPrice;
  }
}
