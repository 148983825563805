import {
  Egsp,
  ICartItemData,
  ICartItemDataPromotions,
  PaymentTypes,
} from "core/entities/PencilSelling/CartItem/ICartItem";
import {
  IBusinessClientsCardSettings,
  ILandlineSettings,
  IMobileSettings,
} from "core/entities/PencilSelling/Configurations/IConfigurations";
import { Group, PortfolioKeys } from "core/entities/Product/IProduct";
import type { IProductMonthlyPrices } from "helpers/ProductMonthlyPrices";
import ProductMonthlyPrices, {
  ICartItemStepPricesData,
  IMonthlyIntervalItem,
} from "helpers/ProductMonthlyPrices";
import {
  BusinessCasesLandline,
  BusinessCasesMobile,
  IBusinessCasesTypeValues,
} from "core/entities/Product/Tariff/ITariff";
import { IDiscount, PromotionPriceType } from "core/entities/Product/IDiscount";
import { IBenefit } from "core/entities/Product/IBenefit";
import { IPromotion } from "core/entities/PencilSelling/IPromotion";
import {
  formatNumberWithTrimTrailingZeros,
  humandReadableNumber,
} from "helpers/NumberHelpers";
import { BENEFIT_VALUE } from "core/entities/MagentaNext/Tariff/Tariff";
import {
  HAPPY_HOUR_BENEFIT_OPTION_DESCRIPTION,
  LOYALTY_BONUS_TEXT,
} from "constants/const";
import { ICustomPromotion } from "../../entities/PencilSelling/ICustomPromotion";
import { IHappyHourBenefitOptionDescription } from "../../../views/PencilSelling/IPencilSelling";
import { getHappyHourBenefitOptionName } from "../../../helpers/GetBenefitOptions";

/**
 * @interface IMonthlyPrice - monthly price interface for cart item summary
 * @property {number | null} price - monthly price value
 * @property {string[]} descriptions - array of monthly price descriptions
 */
export interface IMonthlyPrice {
  price: number | null;
  priceStepsDescriptions: string[];
  priceSteps: ICartItemStepPricesData[];
  updatedProductContractPeriod: number;
  baseMonthlyIntervalItems: IMonthlyIntervalItem[];
}

type LoyaltyBonus = {
  name: string;
  oncePrice: number;
  monthlyPrice: number;
  loyaltyBonusText: string;
  loyaltyBonusDescription: string;
};

export interface ILoyaltyBonusSummaryRenderData extends LoyaltyBonus {
  freeMonths: number;
}

export type Benefits = {
  name: string;
  items: IItemExport[];
};

/**
 * @interface IProductInfoDescription - product description interface for cart item summary
 * @property {string | null} summary - product summary markup
 * @property {string | null} text - product text markup
 */
interface IProductInfoDescription {
  summary: string | null;
  text: string | null;
}

/**
 * @interface IItemExport - product export interface for cart item summary
 * @description - this interface is used to export cart item data to the backend for use
 * @property {string} name - product name
 * @property {string | null} description - product description
 * @property {string} details - product details is the contract perdiod of a product...
 * @property {number | null} monthlyPrice - monthly price value
 * @property {string | null} monthlyPriceSuffix - monthly price suffix
 * @property {string} monthlyPriceDescription - All monthly price descriptions joined by a delimiter
 * @property {number} oncePrice - once price value
 * @property {Benefits} benefits - benefits object
 * @property {LoyaltyBonus} loyaltyBonus - loyalty bonus object
 * @property {IItemExport[]} additions - array of additional items that contain partial data of the IItemExport interface
 * @property {string} note - item note. It is used to display additional information about the item. Note
 */

export enum IAdditionsType {
  DEVICE = "device",
  PROMOTION = "promotion",
  MULTI_SIM = "multiSim",
}

export interface ISummaryAdditionsItem {
  name: string;
  promotionType: PromotionPriceType | null;
  totalPriceDescription: string | null;
  monthlyPrice?: number | null;
  oncePrice?: number | null;
  description: IProductInfoDescription | null;
}

export interface ISummaryAdditions {
  name: string;
  type: IAdditionsType;
  items: ISummaryAdditionsItem[];
}

export interface IItemExport {
  name: string;
  description?: string | null;
  details?: string;
  monthlyPrice?: IMonthlyPrice["price"];
  monthlyPriceSuffix?: string | null;
  monthlyPriceDescription?: string;
  oncePrice?: number | null;
  benefits?: Benefits;
  loyaltyBonus?: LoyaltyBonus;
  additions?: ISummaryAdditions[];
  note?: string;
}

export interface IConfigurationItemExport extends IItemExport {
  additionalRenderData: {
    itemOptionsAreExist: boolean;
    additionalDescription?: string | null;
    productDescriptionData?: IProductInfoDescription | null;
    monthlyPriceStepsDescriptions: string[];
    monthlyPriceStepsData: ICartItemStepPricesData[];
    totalOncePrice: number;
    provisionText: string | null;
    productBaseName: string;
    hasProvisionFee: boolean;
    loyaltyBonus: ILoyaltyBonusSummaryRenderData;
    reducedOncePrice: number;
    averagePriceIsDisplayed: boolean;
    savingsTotalValue: number;
  };
  customerPricePrefix: string;
  monthlyStattPriceDescr: string | null;
  onceStattPriceDescr: string | null;
  happyHourBenefitOptionDescription: IHappyHourBenefitOptionDescription | null;
}

type IItemSettings =
  | IMobileSettings
  | ILandlineSettings
  | IBusinessClientsCardSettings
  | { businessCase: IBusinessCasesTypeValues }
  | null;

/**
 * Presenter for the summary of a cart item.
 */
export class CartItemSummaryPresenter {
  private item: ICartItemData;

  private settings: IItemSettings;

  private monthlyPriceHelper: IProductMonthlyPrices;

  private customerPricePrefix: string;

  private reducedOncePrice: number;

  constructor(
    item: ICartItemData,
    settings: IItemSettings,
    customerPricePrefix?: string
  ) {
    this.item = item;
    this.settings = settings;
    this.customerPricePrefix = customerPricePrefix;
    this.monthlyPriceHelper = new ProductMonthlyPrices(
      item.promotions,
      item.paymentType === PaymentTypes.MONTHLY ? item.price.monthly : null,
      item.price.originalPrice, // #1195
      item.contractPeriod,
      item.quantity,
      this.isTariffOrCard,
      item.paymentType,
      this.item.price.frameworkDiscountPercentageValue,
      this.item.price.monthlyPriceBeforeFrameworkDiscount
    );
    this.reducedOncePrice = this.getReducedOncePrice(
      this.item.promotions,
      this.item.quantity
    );
  }

  get priceSuffix(): string | null {
    return this.item.price?.suffix || null;
  }

  get additionalDescription(): string | null {
    return this.item.additionalDescription || null;
  }

  productDescription(): IProductInfoDescription | null {
    const { description } = this.item;

    return description || null;
  }

  private additionalText(): string {
    if (this.item.group !== Group.device) return "";

    if (
      this.item.paymentType === PaymentTypes.MONTHLY &&
      this.item.egsp &&
      this.item.egsp !== Egsp.NONE
    )
      return this.item.egsp === Egsp.DEFAULT
        ? "im Endgeräte-Servicepaket"
        : "im Endgeräte-Servicepaket Plus";
    return "";
  }

  getDisplayName(nameSuffix = ""): string {
    const namePrefix = this.item.isFungible ? `${this.item.quantity}x` : "";
    return `${namePrefix} ${
      this.item.name
    } ${nameSuffix} ${this.additionalText()}`;
  }

  get contractPeriod(): string {
    if (
      this.item.paymentType === PaymentTypes.ONCE &&
      (this.item.group === Group.device || !this.item.contractPeriod)
    )
      return "";

    if (!this.item.contractPeriod) return "Keine Mindestvertragslaufzeit";

    const monthText = this.item.contractPeriod > 1 ? "Monate" : "Monat";
    return `Mindestvertragslaufzeit ${this.item.contractPeriod} ${monthText}`;
  }

  static formatPrice(price: number) {
    return price.toFixed(2).replace(".00", "").replace(".", ",");
  }

  static getDiscountText(discount: IDiscount): string {
    if (discount.kind === PromotionPriceType.CUSTOM_PROMOTION) {
      const monthlyName = discount.interval > 1 ? "Monate" : "Monat";
      return discount.interval ? `${discount.interval} ${monthlyName}` : "";
    }

    return "";
    // Temporary hidden
    // if (typeof discount.value !== "number") return "";
    //
    // if (discount.kind === PromotionPriceType.PERMANENT_MONTHLY_DISCOUNT)
    //   return "";
    //
    // let promotionPrice = "";
    //
    // if (discount.kind === PromotionPriceType.MONTHLY_DISCOUNT) {
    //   promotionPrice = CartItemSummaryPresenter.formatPrice(
    //     this.item.price.monthly - discount.value
    //   );
    // }
    //
    // if (discount.kind === PromotionPriceType.MONTHLY) {
    //   promotionPrice = CartItemSummaryPresenter.formatPrice(discount.value);
    // }
    //
    // if (!promotionPrice) return "";
    //
    // const isMonthly =
    //   discount.kind === PromotionPriceType.MONTHLY ||
    //   discount.kind === PromotionPriceType.MONTHLY_DISCOUNT;
    //
    // const promotionPriceSuffix = isMonthly ? "€ mtl." : "€ einmalig";
    //
    // return `(${promotionPrice}${promotionPriceSuffix})`;
  }

  // TODO: refactor our data, so provision display can be implemented data driven
  private withoutProvision(): boolean {
    if (this.settings && "businessCase" in this.settings) {
      return (
        this.settings?.businessCase ===
          BusinessCasesLandline.BESTANDSKUNDE_DP ||
        this.settings?.businessCase ===
          BusinessCasesLandline.BESTANDSKUNDE_TP ||
        this.settings?.businessCase ===
          BusinessCasesLandline.BREITBAND_NEUKUNDE ||
        this.settings?.businessCase === BusinessCasesMobile.VERLAENGERUNG
      );
    }
    return false;
  }

  get isTariffOrCard(): boolean {
    return this.item.group === Group.tariff || this.item.group === Group.card;
  }

  monthlyPrice(): IMonthlyPrice | null {
    const priceHelper = this.monthlyPriceHelper;
    const [{ price: startPrice }] = priceHelper.monthlyPriceSteps;

    return {
      priceStepsDescriptions: priceHelper.monthlyPriceStepsDescriptions,
      priceSteps: priceHelper.monthlyPriceSteps,
      price: startPrice,
      baseMonthlyIntervalItems: priceHelper.baseMonthlyIntervalItems,
      updatedProductContractPeriod: priceHelper.updatedProductContractPeriod,
    };
  }

  oncePrice(): number | null {
    if (this.isTariffOrCard) {
      return this.provisionPrice();
    }

    // search for once price percentage promotion
    const oncePercentagePromotion = this.item.promotions.find(
      (promotion) =>
        promotion.discount.kind === PromotionPriceType.DISCOUNT_IN_PERCENT
    );

    const oncePercentagePromotionValue = oncePercentagePromotion
      ? 1 - oncePercentagePromotion.discount.value / 100
      : 1;

    const price = this.item.isFungible
      ? (this.item.price[PaymentTypes.ONCE] || 0) *
        this.item.quantity *
        oncePercentagePromotionValue
      : (this.item.price[PaymentTypes.ONCE] || 0) *
        oncePercentagePromotionValue;

    return this.item.paymentType === PaymentTypes.ONCE ? price : null;
  }

  provisionPrice(): number | null {
    if (this.withoutProvision()) return 0;
    let provisionPrice = this.item.price.provision || 0;

    // search for once price percentage promotion
    const provisionPercentagePromotion = this.item.promotions.find(
      (promotion) =>
        promotion.discount.kind ===
        PromotionPriceType.PROVISION_DISCOUNT_IN_PERCENT
    );

    const provisionPercentagePromotionValue = provisionPercentagePromotion
      ? 1 - provisionPercentagePromotion.discount.value / 100
      : 1;

    provisionPrice *= provisionPercentagePromotionValue;

    const provisionPromotion = this.item.promotions.find(
      (promotion) =>
        promotion.discount.kind === PromotionPriceType.PROVISION_DISCOUNT
    );

    if (
      this.item.group === Group.tariff &&
      this.item.portfolio === PortfolioKeys.MOBILE
    ) {
      const mobileSettings = this.settings as IMobileSettings;

      if (!mobileSettings.hasProvisionFee) {
        return null;
      }
    }

    const result = provisionPrice - (provisionPromotion?.discount?.value || 0);

    return result < 0 ? 0 : result;
  }

  getProvisionText(): string {
    if (
      !this.provisionPrice() ||
      !(
        this.settings &&
        "hasProvisionFee" in this.settings &&
        this.settings?.hasProvisionFee
      )
    )
      return "";

    return `zzgl. Bereitstellungspreis von einmalig ${humandReadableNumber(
      this.item.price.provision,
      "€"
    )}`;
  }

  getAverageTariffPriceWithLoyaltyBonus(): string {
    const mobileSettings = this.settings as IMobileSettings;

    return `<span>
    <b>
      Der rechnerische Durchschnittspreis für den gewählten
      Tarif
      ${mobileSettings.hasProvisionFee ? " (inkl.Bereitstellungspreis) " : " "}
      beträgt unter Berücksichtigung des Treuebonus und
      MagentaEINS&nbsp;Vorteils für 24&nbsp;Monate
      <span class="magenta">
        ${this.getReducedTariffMonthlyPrice()}
      </span>
    </b>
  </span>`;
  }

  getReducedTariffMonthlyPrice(): string {
    const settings = this.settings as IMobileSettings;

    const provisionFee = settings.hasProvisionFee
      ? this.item.price.provision
      : 0;

    const result =
      this.item.price.monthly -
      BENEFIT_VALUE -
      (settings.loyaltyBonusValue - provisionFee) / 24;

    return humandReadableNumber(result, "€ mtl.", "roundUp");
  }

  getFreeMonths(): number {
    const settings = this.settings as IMobileSettings;

    const result = Math.floor(
      (settings.loyaltyBonusValue - this.item.price.provision) /
        (this.item.price.monthly - BENEFIT_VALUE)
    );

    return result > 0 ? result : 0;
  }

  static isNumber(value: unknown): boolean {
    return typeof value === "number";
  }

  hasMonthlyPrice(): boolean {
    return CartItemSummaryPresenter.isNumber(this.monthlyPrice()?.price);
  }

  hasOncePrice(): boolean {
    return CartItemSummaryPresenter.isNumber(this.oncePrice());
  }

  static isOnceCustomPromotion(promotion: ICustomPromotion): boolean {
    return promotion.discount.interval <= 0;
  }

  // static isMonthlyCustomPromotion(promotion: ICustomPromotion): boolean {
  //   return promotion.discount.interval > 0;
  // }

  static getPromotionOncePrice = (
    promotion: IPromotion | ICustomPromotion,
    itemQuantity = 1
  ) => {
    if (promotion.discount.kind === PromotionPriceType.DISCOUNT)
      return -promotion.discount.value * itemQuantity;

    if (promotion.discount.kind === PromotionPriceType.CASHBACK) {
      return -promotion.discount.value * itemQuantity;
    }

    if (
      promotion.discount.kind === PromotionPriceType.CUSTOM_PROMOTION &&
      CartItemSummaryPresenter.isOnceCustomPromotion(
        promotion as ICustomPromotion
      )
    )
      return typeof promotion.discount.value === "number"
        ? -promotion.discount.value
        : null;

    return null;
  };

  getLoyaltyBonus = (loyaltyBonus: number): LoyaltyBonus | null => {
    if (loyaltyBonus > 0) {
      return {
        // name: "<span class='magenta'></span>",
        name: "Treuebonus",
        loyaltyBonusText: LOYALTY_BONUS_TEXT,
        oncePrice: -loyaltyBonus,
        monthlyPrice: -(loyaltyBonus / (this.item.contractPeriod || 1)),
        loyaltyBonusDescription: this.getAverageTariffPriceWithLoyaltyBonus(),
      };
    }
    return null;
  };

  getPromotionAddition = () => ({
    name: "Sonderkonditionen",
    type: IAdditionsType.PROMOTION,
    items: this.item.promotions.map((promotion) => ({
      name: `${promotion.description} ${CartItemSummaryPresenter.getDiscountText(
        promotion.discount
      )}`,
      promotionType: promotion.discount.kind,
      totalPriceDescription:
        "totalPriceDescription" in promotion
          ? promotion.totalPriceDescription
          : null,
      monthlyPrice: null,
      oncePrice: CartItemSummaryPresenter.getPromotionOncePrice(
        promotion,
        this.item.quantity
      ),
      description:
        "summaryDescription" in promotion ? promotion.summaryDescription : null,
    })),
  });

  getBenefits = (benefit: IBenefit): Benefits | null => {
    if (benefit)
      return {
        name: benefit.name,
        items: benefit.options
          .filter(
            (option) =>
              option !== null &&
              "portfolio" in this.settings &&
              option.portfolio === this.settings?.portfolio &&
              option.name
          )
          .map((option) => ({
            name: option.name,
            monthlyPrice: -option.value || null,
          })),
      };

    return null;
  };

  pdfExport(benefit?: IBenefit, note?: string, loyaltyBonus = 0): IItemExport {
    const additions: ISummaryAdditions[] = [];

    if (this.item.promotions.length)
      additions.push(this.getPromotionAddition());

    if (this.item.additionalDevices?.length) {
      const smartphoneItems = this.item.additionalDevices.reduce(
        (acc, item) => {
          if (item?.name || item?.manufacturer) {
            const additionalDeviceItem = {
              name: `${item?.manufacturer ? item.manufacturer : ""}${
                item?.manufacturer ? " " : ""
              }${item?.name ? item.name : ""}`,
              oncePrice: item.price
                ? Number(item.price.replace(",", "."))
                : undefined,
              promotionType: null,
              totalPriceDescription: null,
              description: null,
            };
            return [...acc, additionalDeviceItem];
          }

          return acc;
        },
        [] as ISummaryAdditionsItem[]
      );

      if (smartphoneItems.length)
        additions.push({
          name: "Smartphone",
          type: IAdditionsType.DEVICE,
          items: smartphoneItems,
        });
    }
    // Temporary hidden, due to moving multi-sims to extras
    // const multiSimQuantity = this.item.cardData?.multiSim?.quantity;
    //
    // if (multiSimQuantity) {
    //   const multiSimPrice = this.item.cardData?.multiSim?.price;
    //
    //   additions.push({
    //     name: "MultiSIM",
    //     type: IAdditionsType.MULTI_SIM,
    //     items: [
    //       {
    //         name: `${multiSimQuantity} x MultiSIM für je ${multiSimPrice}`,
    //         monthlyPrice: multiSimPrice * multiSimQuantity,
    //         promotionType: null,
    //         totalPriceDescription: null,
    //         description: null,
    //       },
    //     ],
    //   });
    // }

    const monthlyPriceObject = this.monthlyPrice();

    const loyaltyBonusPayload = this.getLoyaltyBonus(loyaltyBonus);

    return {
      name: this.getDisplayName(),
      details: this.contractPeriod,
      description: this.productDescription()
        ? Object.values(this.productDescription()).join("")
        : "",
      monthlyPrice: monthlyPriceObject.price,
      monthlyPriceSuffix: this.priceSuffix,
      oncePrice: this.oncePrice(),
      additions,
      benefits: this.getBenefits(benefit),
      loyaltyBonus: loyaltyBonusPayload
        ? {
            ...loyaltyBonusPayload,
            name: `<span class='magenta'>${loyaltyBonusPayload.name}</span>`,
          }
        : null,
      note,
    };
  }

  // Subtract monthly price of each addition from each price step ( addition monthly price comes as null or negative value
  static calculateCartItemPriceSteps(
    priceSteps: ICartItemStepPricesData[],
    additions: (IItemExport | ISummaryAdditionsItem)[]
  ) {
    return priceSteps.map((priceStep) => {
      const price = additions.reduce((acc, additionItem) => {
        if (additionItem.monthlyPrice) {
          return acc + additionItem.monthlyPrice < 0
            ? 0
            : acc + additionItem.monthlyPrice;
        }
        return acc;
      }, priceStep.price);
      return {
        ...priceStep,
        price,
      };
    });
  }

  static getAdditionByType(
    additions: ISummaryAdditions[],
    type: IAdditionsType
  ): ISummaryAdditionsItem[] {
    return (
      additions.find((additionItem) => additionItem.type === type)?.items || []
    );
  }

  static getCashbackPromotionsAveragePrice(
    cashbackPromotions: ICartItemDataPromotions,
    contractPeriod: number
  ) {
    return (
      cashbackPromotions.reduce(
        (acc, promotionItem) => acc + -promotionItem.discount.value,
        0
      ) / contractPeriod
    );
  }

  getReducedOncePrice(
    promotions: ICartItemDataPromotions,
    itemQuantity: number
  ) {
    const itemOncePrice = this.oncePrice() || 0;
    const promotionsTotalValue = promotions
      .filter(
        (promotion) => promotion.discount.kind !== PromotionPriceType.CASHBACK
      )
      .reduce((acc, promotionItem) => {
        const promotionValue = CartItemSummaryPresenter.getPromotionOncePrice(
          promotionItem,
          itemQuantity
        );
        return promotionValue ? promotionValue + acc : acc;
      }, 0);
    const result = Number((itemOncePrice + promotionsTotalValue).toFixed(2));
    return result < 0 ? 0 : result;
  }

  calculateTotalOncePrice(additions: ISummaryAdditionsItem[]): number {
    const additionsTotalOncePrice = additions.reduce(
      (acc, additionItem) =>
        additionItem?.oncePrice ? additionItem.oncePrice + acc : acc,
      0
    );
    return additionsTotalOncePrice + this.reducedOncePrice;
  }

  getOriginalMonthlyPrice(): number {
    return typeof this.item.price.monthlyPriceBeforeFrameworkDiscount ===
      "number"
      ? this.item.price.monthlyPriceBeforeFrameworkDiscount
      : this.item.price.monthly;
  }

  getOriginalOncePrice(): number | null {
    const withoutProvision = this.withoutProvision();
    if (this.isTariffOrCard && withoutProvision) {
      return null;
    }

    if (this.isTariffOrCard && !withoutProvision) {
      return typeof this.item.price.provisionPriceBeforeFrameworkDiscount ===
        "number"
        ? this.item.price.provisionPriceBeforeFrameworkDiscount
        : this.item.price.provision;
    }

    if (this.item.paymentType === PaymentTypes.ONCE) {
      return this.item.price.once;
    }
    return null;
  }

  getSavingsTotalValue(
    contractPeriod: number,
    baseMonthlyIntervalItems: IMonthlyIntervalItem[]
  ) {
    const originalOncePrice = this.getOriginalOncePrice() || 0;
    const originalMonthlyPrice = this.getOriginalMonthlyPrice();
    let result: number;

    result = baseMonthlyIntervalItems.reduce(
      (acc, monthlyIntervalItem) =>
        acc + (originalMonthlyPrice - monthlyIntervalItem.value),
      0
    );

    result += originalOncePrice - this.reducedOncePrice;

    result += this.item.promotions.reduce(
      (acc, promotionItem) =>
        promotionItem.discount.kind === PromotionPriceType.CASHBACK
          ? acc + promotionItem.discount.value
          : acc,
      0
    );

    return result;
  }

  getMonthlyStattPriceDescr(): string | null {
    const currentMonthlyPrice = this.monthlyPrice().price;
    const originalMonthlyPrice = this.getOriginalMonthlyPrice();

    return currentMonthlyPrice < originalMonthlyPrice
      ? `${formatNumberWithTrimTrailingZeros(originalMonthlyPrice)} €`
      : null;
  }

  getOnceStattPriceDescr(): string | null {
    const currentOncePrice = this.oncePrice();
    const originalOncePrice = this.getOriginalOncePrice();

    if (typeof originalOncePrice === "number") {
      return currentOncePrice < originalOncePrice
        ? `${formatNumberWithTrimTrailingZeros(originalOncePrice)} €`
        : null;
    }

    return null;
  }

  configurationExport(
    benefit?: IBenefit,
    note?: string,
    loyaltyBonus = 0
  ): IConfigurationItemExport {
    const pdfExportPayload = this.pdfExport(benefit, note, loyaltyBonus);
    const itemOptionsAreExist = !!(
      pdfExportPayload.additions.length ||
      !!pdfExportPayload.benefits ||
      !!pdfExportPayload.loyaltyBonus
    );
    const loyaltyBonusPayload = this.getLoyaltyBonus(loyaltyBonus);
    const monthlyPriceObject = this.monthlyPrice();
    const additionalDevicesItems = CartItemSummaryPresenter.getAdditionByType(
      pdfExportPayload.additions,
      IAdditionsType.DEVICE
    );
    const totalOncePrice = this.calculateTotalOncePrice(additionalDevicesItems);

    let benefitTotalValue = 0;

    if (pdfExportPayload.benefits?.items?.length) {
      benefitTotalValue = pdfExportPayload.benefits?.items.reduce(
        (acc, benefitOption) =>
          benefitOption.monthlyPrice ? acc + benefitOption.monthlyPrice : acc,
        0
      );

      monthlyPriceObject.priceSteps =
        CartItemSummaryPresenter.calculateCartItemPriceSteps(
          monthlyPriceObject.priceSteps,
          pdfExportPayload.benefits.items
        );
    }

    monthlyPriceObject.baseMonthlyIntervalItems.forEach((monthIntervalItem) => {
      const result = monthIntervalItem.value + benefitTotalValue;
      if (result >= 0) {
        monthIntervalItem.value = result;
      }
    });

    const cashbackPromotions = this.item.promotions.reduce(
      (acc, promotionItem) => {
        if (promotionItem.discount.kind === PromotionPriceType.CASHBACK) {
          return [
            ...acc,
            {
              ...promotionItem,
              discount: {
                ...promotionItem.discount,
                value: promotionItem.discount.value * this.item.quantity,
              },
            },
          ];
        }
        return acc;
      },
      [] as ICartItemDataPromotions
    );
    const cashbackPromotionsAveragePrice =
      CartItemSummaryPresenter.getCashbackPromotionsAveragePrice(
        cashbackPromotions,
        monthlyPriceObject.updatedProductContractPeriod
      );
    const monthlyAveragePrice = ProductMonthlyPrices.getAverageMonthlyPrice(
      monthlyPriceObject.baseMonthlyIntervalItems,
      monthlyPriceObject.updatedProductContractPeriod
    );
    const reducedOnceAveragePrice =
      this.reducedOncePrice / monthlyPriceObject.updatedProductContractPeriod;

    const updatedAveragePrice =
      monthlyAveragePrice +
      cashbackPromotionsAveragePrice +
      reducedOnceAveragePrice;
    const monthlyPrice = this.item.price.monthly + benefitTotalValue;
    const averagePriceIsDisplayed = monthlyPrice > updatedAveragePrice;

    if (averagePriceIsDisplayed) {
      monthlyPriceObject.priceStepsDescriptions.push(
        ProductMonthlyPrices.priceAverageText(updatedAveragePrice)
      );
    }

    if (loyaltyBonusPayload) {
      monthlyPriceObject.priceSteps = monthlyPriceObject.priceSteps.map(
        (priceStep) => ({
          ...priceStep,
          price: priceStep.price + loyaltyBonusPayload.monthlyPrice,
        })
      );
    }

    return {
      ...pdfExportPayload,
      happyHourBenefitOptionDescription: getHappyHourBenefitOptionName(benefit)
        ? HAPPY_HOUR_BENEFIT_OPTION_DESCRIPTION
        : null,
      monthlyPriceDescription:
        monthlyPriceObject.priceStepsDescriptions.join("<br />"),
      monthlyStattPriceDescr: this.getMonthlyStattPriceDescr(),
      onceStattPriceDescr: this.getOnceStattPriceDescr(),
      additionalRenderData: {
        itemOptionsAreExist,
        totalOncePrice,
        averagePriceIsDisplayed,
        savingsTotalValue: this.getSavingsTotalValue(
          monthlyPriceObject.updatedProductContractPeriod,
          monthlyPriceObject.baseMonthlyIntervalItems
        ),
        reducedOncePrice: this.reducedOncePrice,
        additionalDescription: this.additionalDescription,
        productDescriptionData: this.productDescription(),
        monthlyPriceStepsDescriptions:
          monthlyPriceObject.priceStepsDescriptions,
        monthlyPriceStepsData: monthlyPriceObject.priceSteps,
        provisionText: this.getProvisionText(),
        productBaseName: this.item.name,
        hasProvisionFee: !!(
          this.settings &&
          "hasProvisionFee" in this.settings &&
          this.settings?.hasProvisionFee
        ),
        loyaltyBonus: loyaltyBonusPayload
          ? {
              ...loyaltyBonusPayload,
              freeMonths: this.getFreeMonths(),
            }
          : null,
      },
      customerPricePrefix: this.customerPricePrefix,
    };
  }
}
