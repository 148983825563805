import React from "react";

// Extend inputfield number check on Firefox
const onlyNumber = (event: React.KeyboardEvent): void => {
  if (!event.key.match(/^[0-9,]+$/)) event.preventDefault();
};

const preventDecimal = (event: React.KeyboardEvent): void => {
  if (event.key === "," || event.key === ".") {
    event.preventDefault();
  }
};

export { onlyNumber, preventDecimal };
