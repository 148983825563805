import React, { useState } from "react";
import { observer } from "mobx-react";
import ActionRemoveDefault from "@dtpk-cc/components/dist/icons/Action/Remove/Default";
import { IStepActionHeaderNavMap } from "hooks/PencilSelling/useStepActionHeaderNavMap";
import AmountPicker from "elements/new-design/AmountPicker";
import StepHeader from "../index";
import NavTabButton from "../NavTabButton";
import WarningModal from "../../WarningModal";
import RenameConfigurationModal from "../../../../elements/new-design/RenameConfigurationModal/RenameConfigurationModal";
import { trackClick } from "../../../../helpers/reactTracking";
import { ConfigurationsEntity } from "../../../../core/entities/PencilSelling/Configurations/Configurations";
import * as styles from "./step-header-with-actions.module.scss";
import { BaseOffer } from "../../../../core/entities/PencilSelling/BaseOffer/BaseOffer";
import { CustomerType } from "../../../../core/entities/PencilSelling/Customer/ICustomer";
import DisplayConfigurationFlyoutBtn from "../DisplayConfigurationFlyoutBtn";
import StepHeaderActionBtn from "../StepHeaderActionBtn";

type StepActionHeaderProps = {
  navMap: IStepActionHeaderNavMap[];
  title: string;
  subtitle: string;
  activeTabIndex: number;
  trackingContext: string;
  amountPickerOnChange?: () => void;
  removeConfigurationHandler?: () => void;
  disabledPlus?: boolean;
  configurationsStore: ConfigurationsEntity;
  offerStore: BaseOffer;
  displayConfigurationFlyout: (flyoutKey: string) => void;
};

const StepActionHeader = ({
  title,
  subtitle,
  navMap,
  activeTabIndex,
  trackingContext,
  amountPickerOnChange = () => {},
  removeConfigurationHandler = () => {},
  disabledPlus = false,
  configurationsStore,
  offerStore,
  displayConfigurationFlyout,
}: StepActionHeaderProps) => {
  const [removeConfigModalIsOpen, setRemoveConfigModalIsOpen] = useState(false);
  const activeConfigurationAmount =
    configurationsStore.getActiveConfigurationAmount();
  const { customerType } = offerStore.customer.get();
  const removeConfigModalToggleHandler = () =>
    setRemoveConfigModalIsOpen((prev) => !prev);

  const updateActiveConfigurationAmount = (value: number) =>
    configurationsStore.setActiveConfigurationAmount(value);

  const onAmountPickerClick = (analyticContext: string, add: boolean) => {
    trackClick(analyticContext, trackingContext);
    const newAmount = add
      ? activeConfigurationAmount + 1
      : activeConfigurationAmount - 1;
    updateActiveConfigurationAmount(newAmount);
    amountPickerOnChange();
  };

  return (
    <StepHeader
      customClass={styles.stepActionHeader}
      title={title}
      subtitle={subtitle}
      titleCustomClass={styles.title}
    >
      <RenameConfigurationModal
        configurationsStore={configurationsStore}
        trackingContext={trackingContext}
      />
      <div className={styles.mainActionGroupWrapper}>
        <AmountPicker
          customClass={`${
            customerType === CustomerType.PRIVATE ? styles.hidden : ""
          } ${styles.amountPicker}`}
          onSubtractClick={() => {
            onAmountPickerClick("konfigurationsmenge.reduzieren", false);
          }}
          onAddClick={() => {
            onAmountPickerClick("konfigurationsmenge.erhöhen", true);
          }}
          value={activeConfigurationAmount}
          disabledPlus={disabledPlus}
        />
        <div className={styles.navButtonsWrapper}>
          {navMap.map((navItem, index) => (
            <NavTabButton
              key={navItem.title}
              text={navItem.title}
              onClick={navItem.onClick}
              isActive={activeTabIndex === index}
              disabled={navItem.disabled}
            />
          ))}
        </div>
      </div>
      <DisplayConfigurationFlyoutBtn
        customClass={styles.showFlyoutBtn}
        onClick={displayConfigurationFlyout}
      />
      <StepHeaderActionBtn
        onClick={removeConfigModalToggleHandler}
        icon={ActionRemoveDefault}
        text="Löschen"
      />
      <WarningModal
        onSubmit={() => {
          removeConfigurationHandler();
          removeConfigModalToggleHandler();
        }}
        onCancel={removeConfigModalToggleHandler}
        isOpen={removeConfigModalIsOpen}
        titleText={"Löschen der Konfiguration"}
        mainText={
          "Mit Bestätigung werden alle Einstellungen der Konfiguration gelöscht."
        }
      />
    </StepHeader>
  );
};

export default observer(StepActionHeader);
