import React from "react";
import {
  Badge,
  Button,
  CopyText,
  Heading,
  Price,
  Tile,
} from "@dtpk-cc/components";

import "elements/TileItem/tile-item.scss";
import { IPrice } from "core/entities/Product/IPrice";

import ActionSuccessSelected from "@dtpk-cc/components/dist/icons/Action/Success/Selected";
import PromotionSelectionIcon from "../../../../assets/images/icons/pencil-selling-tool/addons-selection/addon-promotion-selection.svg";
import { PaymentTypes } from "../../../core/entities/PencilSelling/CartItem/ICartItem";
import AmountPicker from "../AmountPicker";

import * as styles from "./addon-item.module.scss";

type AddonItemProps = {
  isActive: boolean;
  price: IPrice;
  name: string;
  quantity: number;
  isProductGroup: boolean;
  isFungible: boolean;
  onClick: () => void;
  onPromotionsClick?: () => void;
  onQuantityChange: (quantity: number) => void;
  additionalPriceSuffix?: string;
  description?: string;
  paymentType?: PaymentTypes;
  contractPeriod: number | null;
  hasPromotions?: boolean;
  customClass?: string;
};

const monthlyPriceDefaultSuffix = "mtl.";
const oncePriceSuffix = "einmalig";

const AddonItem = ({
  isActive,
  price,
  onClick,
  name,
  description,
  quantity,
  onPromotionsClick = () => {},
  onQuantityChange,
  isFungible,
  additionalPriceSuffix = "",
  isProductGroup = false,
  contractPeriod = null,
  paymentType = null,
  hasPromotions = true,
  customClass = "",
}: AddonItemProps) => {
  // For non-active tile
  const monthlyPriceSuffix = `${monthlyPriceDefaultSuffix} ${
    additionalPriceSuffix || ""
  }`;
  const hasMultiplePrices =
    typeof price?.monthly === "number" && typeof price?.once === "number";
  // For activated tile
  const selectedPrice = price[paymentType];
  const priceVariant =
    paymentType === "once" ? oncePriceSuffix : monthlyPriceSuffix;

  return (
    <Tile
      customClass={`
        ${styles.addonItem}
        ${isActive ? styles.active : ""}
        ${customClass}
      `}
      variant="shadow"
      aria-label={`${name} auswählen`}
    >
      {isActive && (
        <Badge customClass={styles.badge} variants={Badge.Variant.flag}>
          <Badge.Paragraph fonts={Badge.Paragraph.Font.additionalHighlight}>
            Ausgewählt
          </Badge.Paragraph>
        </Badge>
      )}
      <Tile.Content customClass={styles.content}>
        <div className={styles.header}>
          <Heading
            variants={Heading.Variant.tertiary}
            customClass={styles.title}
          >
            {name}
          </Heading>
          {description && (
            <CopyText variants={CopyText.Variant.additional}>
              {description}
            </CopyText>
          )}
        </div>
        {/* Price and amount picker and info data */}
        <div className={styles.priceMainContainer}>
          {isActive ? (
            <>
              {/* Selected Price with additional data container */}
              <div className={styles.mainPriceWrapper}>
                <Price
                  variants={[Price.Variant.offering]}
                  suffix={priceVariant}
                  value={selectedPrice}
                />
                {paymentType === PaymentTypes.MONTHLY &&
                  typeof contractPeriod === "number" && (
                    <CopyText variants={CopyText.Variant.additional}>
                      {contractPeriod === 0
                        ? "Keine MVLZ"
                        : `${contractPeriod} monate MVLZ`}
                    </CopyText>
                  )}
              </div>

              {isFungible && (
                <AmountPicker
                  onSubtractClick={() => onQuantityChange(quantity - 1)}
                  onAddClick={() => onQuantityChange(quantity + 1)}
                  value={quantity}
                />
              )}
            </>
          ) : (
            <div>
              {typeof price?.monthly === "number" && (
                <div className={styles.mainPriceWrapper}>
                  <Price
                    variants={[Price.Variant.brand, Price.Variant.offering]}
                    suffix={monthlyPriceSuffix}
                    prefix={isProductGroup ? "ab" : ""}
                    value={price.monthly}
                  />
                </div>
              )}
              {typeof price?.once === "number" && (
                <div
                  className={`${styles.secondaryPriceWrapper} ${
                    !hasMultiplePrices ? styles.withoutMonthly : ""
                  }`}
                >
                  <Price
                    variants={
                      hasMultiplePrices
                        ? null
                        : [Price.Variant.brand, Price.Variant.offering]
                    }
                    suffix={oncePriceSuffix}
                    prefix={`${hasMultiplePrices ? "oder " : ""}${
                      isProductGroup ? "ab" : ""
                    }`}
                    value={price.once}
                  />
                </div>
              )}
            </div>
          )}
        </div>
        {/* Action footer */}
        <div className={styles.footer}>
          <Button
            tabIndex={0}
            onKeyPress={onClick}
            onClick={onClick}
            variants={isActive ? Button.Variant.active : Button.Variant.outline}
            aria-label={`${name} entfernen`}
          >
            {isActive && <Button.Icon icon={ActionSuccessSelected} />}
            {isActive ? "Hinzugefügt" : "Hinzufügen"}
          </Button>
          {isActive && hasPromotions && (
            <Button
              tabIndex={0}
              variants={Button.Variant.bare}
              onClick={onPromotionsClick}
              customClass={styles.promotionSelectionBtn}
            >
              <Button.Icon
                customClass={styles.promotionSelectionBtnIcon}
                icon={PromotionSelectionIcon}
              />
              Aktionen verwalten
            </Button>
          )}
        </div>
      </Tile.Content>
    </Tile>
  );
};

export default AddonItem;
