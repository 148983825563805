import { ITariff } from "core/entities/Product/Tariff/ITariff";

export const getDefaultTariffPrice = (
  tariff: ITariff,
  isBenefitActive: boolean
): number => {
  const rebateOption = tariff.benefits[0]?.options.find(
    (option) => option?.key === "discount"
  );

  const rebate = parseFloat(`${rebateOption?.value || 0}`);

  const price: number = isBenefitActive
    ? tariff.price.monthly - rebate
    : tariff.price.monthly;

  return price;
};
