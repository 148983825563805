import React, { useState, useEffect } from "react";
import { Tile } from "@dtpk-cc/components";
import PriceInput from "elements/PriceInput";
import PeriodInput from "elements/PeriodInput";
import { trackClick } from "helpers/reactTracking";
import {
  ActionEditDefault,
  ActionRemoveDefault,
} from "@dtpk-cc/components/dist/icons";
import { ITariff } from "core/entities/Product/Tariff/ITariff";
import { IPromotion } from "core/entities/PencilSelling/IPromotion";
import CardCategoryItemTitle from "../CardCategoryItemTitle";
import CardCategoryFooter from "../CardCategoryFooter";
import CardCategoryHeader from "../CardCategoryHeader";
import CardCategoryItemControl from "../CardCategoryItemControl";
import * as styles from "../card-category.module.scss";

type PromotionsCardCategoryProps = {
  activeMainCard: ITariff;
  name: string;
  defaultName: string;
  headerCustomClass: string;
  onAsteriskClick: () => void;
  activePromotions: IPromotion[];
  onAddCard: () => void;
  addCardBtnText: string;
  onChangePeriod: (key: string, period: number) => void;
  onChangeName: (key: string, name: string) => void;
  onChangePrice: (key: string, price: number) => void;
  onDeleteCard: (item: IPromotion) => void;
  setTransferButtonIsEnabled: React.Dispatch<React.SetStateAction<boolean>>;
};

const PromotionsCardCategory = ({
  activeMainCard,
  name,
  defaultName,
  headerCustomClass,
  onAsteriskClick,
  activePromotions = [],
  onAddCard,
  addCardBtnText,
  onChangePeriod,
  onChangeName,
  onChangePrice,
  onDeleteCard,
  setTransferButtonIsEnabled,
}: PromotionsCardCategoryProps) => {
  const trackingContext = "aktion";
  const [isNameEditable, setIsNameEditable] = useState(false);

  const validateInputHandler = (inputValue, currentCardKey) => {
    if (activePromotions.length <= 1) return true;

    // Check active promotions name for uniqueness
    const filteredPromotions = activePromotions.filter(
      (cardItem) => cardItem.key !== currentCardKey
    );
    const cardNameIsUnique = !filteredPromotions.some(
      (cardItem) => cardItem.name === inputValue
    );
    return cardNameIsUnique;
  };

  const handleTitleEditableStateChange = (card) => {
    if (isNameEditable && !validateInputHandler(card.name, card.key)) return;
    setIsNameEditable((prev) => !prev);
  };

  useEffect(() => {
    if (activePromotions.length > 1) {
      const cardNames = activePromotions.map((cardItem) => cardItem.name);
      const allPromotionNamesAreUnique = cardNames.every(
        (cardName, index, arr) => arr.indexOf(cardName) === index
      );
      setTransferButtonIsEnabled(
        !!activeMainCard && allPromotionNamesAreUnique
      );
    }

    if (activePromotions.length <= 1) {
      setTransferButtonIsEnabled(!!activeMainCard);
    }
  }, [activePromotions, activeMainCard, setTransferButtonIsEnabled]);

  return (
    <div className={`${styles.cardCategory} ${styles.container}`}>
      {/* Header */}
      <CardCategoryHeader
        headerCustomClass={`${headerCustomClass}`}
        name={name}
        onAsteriskClick={() => {
          onAsteriskClick();
          trackClick("aktionen-asterisk", trackingContext);
        }}
      />
      <Tile
        variant="shadow"
        customClass={`${styles.cardCategory} ${styles.list}`}
      >
        {activePromotions.map((card) => (
          <Tile.Content
            key={card.key}
            customClass={`${styles.cardCategory} ${styles.row}`}
          >
            <div
              className={`${styles.cardCategory} ${styles.column} ${styles.title}`}
            >
              <CardCategoryItemTitle
                isNameEditable={isNameEditable}
                setIsNameEditable={setIsNameEditable}
                cardTitle={card.name}
                defaultName={defaultName}
                isValid={validateInputHandler(card.name, card.key)}
                invalidMessage="Aktionsnamen müssen einzigartig sein. Bitte wählen Sie einen anderen Namen."
                onChange={(e) => {
                  onChangeName(card.key, e.target.value);
                }}
              />
            </div>
            <div
              className={`${styles.cardCategory} ${styles.column} ${styles.controls}`}
            >
              <CardCategoryItemControl
                customClass={`${styles.cardCategory} ${styles.edit}`}
                onClick={() => {
                  onDeleteCard(card);
                  trackClick(`${card.name}.löschen`, trackingContext);
                }}
                hintText="Tarif löschen"
                icon={ActionRemoveDefault}
              />
              <CardCategoryItemControl
                customClass={`${styles.cardCategory} ${styles.edit}`}
                onClick={() => {
                  trackClick(`${card.name}.ändern`, trackingContext);
                  handleTitleEditableStateChange(card);
                }}
                hintText="Tarif ändern"
                icon={ActionEditDefault}
              />
            </div>
            <div
              className={`${styles.cardCategory} ${styles.column} ${styles.number}`}
            >
              <PeriodInput
                value={card.contractPeriod}
                onChange={(period: number) => onChangePeriod(card.key, period)}
                onClick={() => {
                  trackClick("dauer", trackingContext);
                }}
              />
            </div>
            <div
              className={`${styles.cardCategory} ${styles.column} ${styles.number}`}
            >
              <PriceInput
                value={card.discount?.value ?? 0}
                onChange={(price: number) => onChangePrice(card.key, price)}
                suffix="€"
                onClick={() => trackClick("preis", trackingContext)}
              />
            </div>
          </Tile.Content>
        ))}
        <CardCategoryFooter
          onAddCard={onAddCard}
          addCardBtnText={addCardBtnText}
        />
      </Tile>
    </div>
  );
};
export default PromotionsCardCategory;
