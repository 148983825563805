import React, { useMemo } from "react";
import { observer } from "mobx-react";
import { Heading, CopyText } from "@dtpk-cc/components";
import SecondaryCardsSelectionSummary from "./SecondaryCardsSelectionSummary";
import {
  ICartConfiguration,
  ICartConfigurationType,
} from "../../../../../core/entities/PencilSelling/Configurations/IConfigurations";
import { useStores } from "../../../../../stores/PrivateClient";
import {
  CardSection,
  CardsPresenter,
} from "../../../../../core/presenter/PencilSelling/CardsPresenter";
import SecondaryCardsSection from "./SecondaryCardsSection";
import { PortfolioKeys } from "../../../../../core/entities/Product/IProduct";

import * as styles from "./secondary-cards-selection.module.scss";
import {
  calculateSecondaryCardsSumAveragePrice,
  getCashbackPromotions,
  mapCashbackPromotions,
} from "../../../../../components/new-design/Summary/helpers";
import TileContainer from "../../../../../components/new-design/TileContainer";

type SecondaryCardsSelectionProps = {
  linkedConfiguration: ICartConfiguration;
  trackingContext: string;
  coreCardSections: CardSection[];
  secondaryCardSections: CardSection[];
  cardsPresenter: CardsPresenter;
};

const footNoteMap = [
  {
    title: "1) Zugehöriger Mobilfunktarif:",
    text: ` Bei Aktivierung wird der zugehörige Mobilfunktarif sowohl im "Preis
            für x Mobilfunkkarten" als auch im "rechnerischen 2-Jahres-Preis pro
            Karte" einbezogen und im Angebot ausgewiesen. Bei Deaktivierung
            werden ausschließlich die ausgewählten Zusatzkarten berücksichtigt.`,
  },
  {
    title: "2) Preis für X Mobilfunkkarten:",
    text: `Zeigt die reellen monatlichen Kosten für alle ausgewählten
            Mobilfunkkarten (inkl. Mobilfunk-Haupttarif und allen Zusatzkarten)
            unter Berücksichtigung evtl. ausgewählter Aktionen.`,
  },
  {
    title: "3) rechnerischer 2-Jahres-Preis pro Karte:",
    text: `Zeigt den durchschnittlichen monatlichen Preis pro Karte (inkl.
            Mobilfunk-Haupttarif und allen Zusatzkarten) unter Berücksichtigung
            aller evtl. anfallenden Bereitstellungsgebühren und evtl.
            ausgewählter Aktionen.`,
  },
];

const SecondaryCardsSelection = ({
  linkedConfiguration,
  coreCardSections,
  secondaryCardSections,
  cardsPresenter,
  trackingContext,
}: SecondaryCardsSelectionProps) => {
  const { productsStore, offerStore, configurationsStore } = useStores();
  const linkedConfigurationIsOmitted = linkedConfiguration.data.isOmitted;
  const configurationCart =
    configurationsStore.getActiveConfigurationChildren();
  const cardsInCart = configurationCart.getCards();
  const businessCase = offerStore
    .getBaseOffer()
    .getBusinessCase(ICartConfigurationType.CARD);
  const { customerPricePrefix } = offerStore.getBaseOffer().customer.get();
  const mobileTariffInCart = useMemo(
    () => linkedConfiguration.children.getMobileTariff(),
    [linkedConfiguration.children]
  );
  const mobileTariff = useMemo(() => {
    const mobileTariffInCartKey = mobileTariffInCart.key;
    return productsStore
      .getMobileTariffs()
      .find((tariff) => tariff.key === mobileTariffInCartKey);
  }, [mobileTariffInCart.key, productsStore]);
  const customPromotions = offerStore.getBaseOffer().getCustomPromotions();
  const benefitIsActive = linkedConfiguration.data.isBenefitActive;
  const inheritedDataVolume =
    benefitIsActive && mobileTariff.dataVolumeMagentaOne
      ? mobileTariff.dataVolumeMagentaOne
      : mobileTariff.dataVolume;

  const cardSectionName = (section) =>
    section.size ? `${section.name} ${section.size}` : section.name;

  const cardsWithMobileTariffInCart = [...cardsInCart];

  if (!linkedConfigurationIsOmitted) {
    cardsWithMobileTariffInCart.push(mobileTariffInCart);
  }

  const summaryData = calculateSecondaryCardsSumAveragePrice({
    cardsInCart,
    customerPricePrefix,
    businessCase,
    mobileTariffConfiguration: !linkedConfigurationIsOmitted
      ? linkedConfiguration
      : null,
  });

  const mappedCashbackPromotions = mapCashbackPromotions(
    getCashbackPromotions(cardsWithMobileTariffInCart)
  );

  const toggleLinkedConfigurationIsOmittedHandler = () => {
    configurationsStore.setConfigurationData(linkedConfiguration.key, {
      isOmitted: !linkedConfigurationIsOmitted,
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <SecondaryCardsSelectionSummary
          linkedConfigurationIsOmitted={!linkedConfigurationIsOmitted}
          toggleLinkedConfigurationIsOmittedHandler={
            toggleLinkedConfigurationIsOmittedHandler
          }
          cardsTotalAmount={cardsWithMobileTariffInCart.length}
          mobileTariffName={mobileTariffInCart.name}
          totalPriceData={summaryData.totalPriceData}
          averagePricePerCard={summaryData.averagePrice}
          mappedCashbackPromotions={mappedCashbackPromotions}
        />
        {!!coreCardSections.length && !mobileTariff.isYoung && (
          <div className="m-b-36">
            <Heading
              customClass="m-b-24"
              variants={[Heading.Variant.tertiary, Heading.Variant.highlight]}
            >
              Zusatzkarten
            </Heading>
            {coreCardSections.map((section, index) => (
              <SecondaryCardsSection
                key={`${section.key as string}${index}`}
                section={section}
                name={cardSectionName(section)}
                dataVolume={inheritedDataVolume}
                cardsPresenter={cardsPresenter}
                businessCase={businessCase}
                mobileTariff={mobileTariff}
                inheritedDataVolume={inheritedDataVolume}
                benefitIsActive={benefitIsActive}
                customPromotions={customPromotions[PortfolioKeys.MOBILE]}
                trackingContext={trackingContext}
                configurationCart={configurationCart}
                cardsInCart={cardsInCart}
              />
            ))}
          </div>
        )}
        {!!secondaryCardSections.length && (
          <div>
            <Heading
              customClass="m-b-24"
              variants={[Heading.Variant.tertiary, Heading.Variant.highlight]}
            >
              Weitere Zusatzkarten
            </Heading>
            {secondaryCardSections.map((section, index) => (
              <SecondaryCardsSection
                key={`${section.key as string}${index}`}
                section={section}
                name={cardSectionName(section)}
                dataVolume={section.dataVolume as string}
                cardsPresenter={cardsPresenter}
                businessCase={businessCase}
                mobileTariff={mobileTariff}
                inheritedDataVolume={inheritedDataVolume}
                benefitIsActive={benefitIsActive}
                customPromotions={customPromotions[PortfolioKeys.MOBILE]}
                trackingContext={trackingContext}
                configurationCart={configurationCart}
                cardsInCart={cardsInCart}
              />
            ))}
          </div>
        )}
        <TileContainer customClass="m-t-24">
          {footNoteMap.map((footNoteMapItem) => (
            <CopyText
              key={footNoteMapItem.title}
              customClass={styles.footNoteItem}
            >
              <CopyText
                variants={CopyText.Variant.highlight}
                tag="span"
                customClass="m-l-4 m-r-4"
              >
                {footNoteMapItem.title}
              </CopyText>
              {footNoteMapItem.text}
            </CopyText>
          ))}
        </TileContainer>
      </div>
    </div>
  );
};

export default observer(SecondaryCardsSelection);
