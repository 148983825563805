// Calculate various monthly prices depending on the active promotions
// As of right now, all tariffs can noly have two monthly promotions
import { PromotionPriceType } from "core/entities/Product/IDiscount";
import type { IPromotion } from "core/entities/PencilSelling/IPromotion";
import {
  ICartItemDataPromotions,
  PaymentTypes,
} from "../core/entities/PencilSelling/CartItem/ICartItem";
import { CustomPromotionTypes } from "../core/entities/PencilSelling/ICustomPromotion";
import { formatNumberWithTrimTrailingZeros } from "./NumberHelpers";

/**
 * Interface representing the start price of a promotion.
 * @property {number} price - The starting value of a promotion
 * @property {number } from - The starting month of a promotion
 * @property {number}    to - The ending month of a promotion
 */
export interface IPromotionStartPrice {
  price: number | null;
  to: number | null;
  from: number | null;
}

export interface ICartItemStepPricesData {
  from: number | null;
  price: number | null;
}

export interface ICartItemStepPricesDescriptions {
  description: string;
}

export interface IMonthlyIntervalItem {
  month: number;
  value: number;
  applyBeforeFrameworkContract?: boolean;
}

/**
 * Interface that describes the ProductMonthlyPrices class.
 * @property {IPromotion} monthlyPromotions - The monthly promotions of a product
 * @property {number } monthlyPrice - The monthly price of a product
 * @property {number} averageMonthlyPrice - The average monthly price of a product
 * @property {number} originalPrice - The original price of a product
 * @property {number} contractPeriod - The contract period of a product
 * @property {ICartItemStepPricesData[]} monthlyPriceSteps - The contract period of a product
 * @property {string[]} monthlyPriceStepsDescriptions - The contract period of a product
 * @property {number} cartItemQuantity - cart item quantity
 * @property {number} isTariffOrCard - defines is current cart item a Card or a Tariff
 */
export interface IProductMonthlyPrices {
  monthlyPromotions: ICartItemDataPromotions;
  monthlyPrice: number | null;
  originalPrice?: number;
  contractPeriod: number;
  monthlyPriceSteps: ICartItemStepPricesData[];
  monthlyPriceStepsDescriptions: string[];
  computeMonthlyExpenses(promotions: IPromotion[]): void;
  cartItemQuantity: number;
  isTariffOrCard: boolean;
  paymentType: PaymentTypes;
  updatedProductContractPeriod: number;
  baseMonthlyIntervalItems: IMonthlyIntervalItem[];
  frameworkDiscountPercentageValue: number | null;
  monthlyPriceBeforeFrameworkDiscount: number | null;
  monthlyPriceBeforeFrameworkDiscountExist: boolean;
}

export default class ProductMonthlyPrices implements IProductMonthlyPrices {
  monthlyPrice: number;

  originalPrice?: number;

  monthlyPromotions: ICartItemDataPromotions;

  contractPeriod: number;

  cartItemQuantity = 1;

  monthlyPriceSteps: ICartItemStepPricesData[];

  monthlyPriceStepsDescriptions: string[];

  isTariffOrCard: boolean;

  paymentType: PaymentTypes;

  updatedProductContractPeriod: number;

  baseMonthlyIntervalItems: IMonthlyIntervalItem[];

  frameworkDiscountPercentageValue: number | null;

  monthlyPriceBeforeFrameworkDiscount: number | null;

  monthlyPriceBeforeFrameworkDiscountExist: boolean;

  /**
   *
   * @param promotions - The list of promotions for the product
   * @param monthlyPrice - The monthly price of a product
   * @param originalPrice - A price after it's contract period which is the originalPrice
   * @param contractPeriod - The contract period of a product
   * @param cartItemQuantity - cart item quantity
   * @param isTariffOrCard - defines is current cart item a Card or a Tariff
   * @param paymentType - defines is current cart item payment type
   * @param frameworkDiscountPercentageValue - defines the percentage of the monthly price reduction by framework contract
   * @param monthlyPriceBeforeFrameworkDiscount - defines the monthly price of the product before framework contract was applied
   */
  constructor(
    promotions: ICartItemDataPromotions,
    monthlyPrice: number | null,
    originalPrice: number | null,
    contractPeriod: number | null,
    cartItemQuantity: number,
    isTariffOrCard: boolean,
    paymentType: PaymentTypes,
    frameworkDiscountPercentageValue: number | null,
    monthlyPriceBeforeFrameworkDiscount: number | null
  ) {
    this.cartItemQuantity = cartItemQuantity;
    this.monthlyPrice = monthlyPrice ?? 0;
    this.originalPrice = originalPrice;
    this.contractPeriod = contractPeriod;
    this.isTariffOrCard = isTariffOrCard;
    this.paymentType = paymentType;
    this.updatedProductContractPeriod = 1;
    this.baseMonthlyIntervalItems = [];
    this.frameworkDiscountPercentageValue =
      typeof frameworkDiscountPercentageValue === "number"
        ? frameworkDiscountPercentageValue * 100
        : 0;
    this.monthlyPriceBeforeFrameworkDiscount =
      monthlyPriceBeforeFrameworkDiscount;
    this.monthlyPriceBeforeFrameworkDiscountExist =
      typeof monthlyPriceBeforeFrameworkDiscount === "number";
    this.computeMonthlyExpenses(promotions);
  }

  /**
   * @description - createMonthlyIntervalItemsArr creates monthly instances that are represented by month value and price for each interval monthly step
   */

  static createMonthlyIntervalItemsArr(
    from: number,
    to: number,
    value: number,
    applyBeforeFrameworkContract = false
  ): IMonthlyIntervalItem[] {
    const result: IMonthlyIntervalItem[] = [];
    let counter = from;

    while (counter <= to) {
      result.push({
        value,
        applyBeforeFrameworkContract,
        month: counter,
      });

      counter += 1;
    }

    return result;
  }

  // * 100 operation occurs to prevent following result on sum operation: 0.1 + 0.2 = 0.3000000...04
  static getAverageMonthlyPrice(
    monthlyIntervalItems: IMonthlyIntervalItem[],
    interval: number
  ) {
    const result = monthlyIntervalItems.reduce(
      (acc, monthlyInterval) => (acc * 100 + monthlyInterval.value * 100) / 100,
      0
    );
    return parseFloat((result / interval).toFixed(2));
  }

  /**
   * @description - createMonthlyPriceSteps creates monthly price steps data items.
   * Each step represents the price change for certain period specified by 'from' field.
   */

  static createMonthlyPriceSteps(monthlyIntervalItems: IMonthlyIntervalItem[]) {
    return monthlyIntervalItems.reduce((acc, monthlyInterval) => {
      const lastPriceStep = acc[acc.length - 1];

      if (lastPriceStep?.price !== monthlyInterval.value) {
        return [
          ...acc,
          {
            price: monthlyInterval.value,
            from: monthlyInterval.month,
            description: ProductMonthlyPrices.priceIntervalText(
              monthlyInterval.month,
              monthlyInterval.value
            ),
          },
        ];
      }

      return acc;
    }, [] as Array<ICartItemStepPricesData & ICartItemStepPricesDescriptions>);
  }

  /**
   * @param promotions - The list of promotions for the product
   * @description - This method splits current cart item contract period in monthlyIntervalItems. Each item represents once month with specified number and the price that customer should pay in this month.
   * When baseMonthlyIntervalItems are created. We check whether we have attached promotions ( two types: MONTHLY_DISCOUNT, MONTHLY ).
   * If promotions exists we split their interval into monthlyIntervalItems.
   * Then we apply promotions monthlyIntervalItems to baseMonthlyIntervalItems.
   * After all monthly items price were updated we use cart item quantity multiplier to get correct prices.
   */

  computeMonthlyExpenses(promotions: ICartItemDataPromotions) {
    if (this.paymentType !== PaymentTypes.MONTHLY) {
      this.monthlyPriceSteps = [{ from: 1, price: this.monthlyPrice }];
      this.monthlyPriceStepsDescriptions = [];
      return;
    }
    const getPromotionsByType = (type: PromotionPriceType) =>
      promotions?.filter((promotion) => promotion.discount.kind === type) || [];

    const calculateReducedByPercentageValue = (
      baseValue,
      percentageValue = 0
    ) => baseValue * (1 - percentageValue / 100);

    const getPromotionCalculationData = (
      baseMonthlyIntervalValue: number,
      isApplyBeforeFrameworkContract: boolean
    ) => {
      const applyMonthlyPriceBeforeFrameworkDiscount =
        isApplyBeforeFrameworkContract &&
        this.monthlyPriceBeforeFrameworkDiscountExist;
      const currentValue = applyMonthlyPriceBeforeFrameworkDiscount
        ? this.monthlyPriceBeforeFrameworkDiscount
        : baseMonthlyIntervalValue;

      return {
        applyMonthlyPriceBeforeFrameworkDiscount,
        currentValue,
      };
    };

    const customMonthlyDiscountPromotions = getPromotionsByType(
      PromotionPriceType.CUSTOM_PROMOTION
    ).filter(
      (promotion) =>
        "type" in promotion &&
        promotion.type === CustomPromotionTypes.PROMOTION_TYPE_MONTHLY_REDUCE
    );

    const monthlyPercentagePromotions = getPromotionsByType(
      PromotionPriceType.MONTHLY_DISCOUNT_IN_PERCENT
    );
    const monthlyPromotions = getPromotionsByType(PromotionPriceType.MONTHLY);
    // Merge GUI MONTHLY_DISCOUNT with Custom MONTHLY_DISCOUNT promotions
    const monthlyDiscountPromotions = [
      ...getPromotionsByType(PromotionPriceType.MONTHLY_DISCOUNT),
      ...customMonthlyDiscountPromotions,
    ];

    this.updatedProductContractPeriod = [
      ...monthlyPercentagePromotions,
      ...monthlyPromotions,
      ...monthlyDiscountPromotions,
    ].reduce(
      (acc, promotion) =>
        promotion.discount.to > acc ? promotion.discount.to : acc,
      this.contractPeriod
    );
    this.baseMonthlyIntervalItems =
      ProductMonthlyPrices.createMonthlyIntervalItemsArr(
        1,
        this.updatedProductContractPeriod,
        this.monthlyPrice
      );

    if (monthlyPercentagePromotions.length) {
      const monthlyPercentagePromotionsIntervalItems =
        monthlyPercentagePromotions
          .map((promotion) =>
            ProductMonthlyPrices.createMonthlyIntervalItemsArr(
              promotion.discount.from,
              promotion.discount.to,
              promotion.discount.value,
              "applyBeforeFrameworkContract" in promotion &&
                promotion.applyBeforeFrameworkContract
            )
          )
          .flat();

      //  Update price
      this.baseMonthlyIntervalItems = this.baseMonthlyIntervalItems.map(
        (monthlyIntervalItem) => {
          const updatedPrice = monthlyPercentagePromotionsIntervalItems.reduce(
            (acc, { value, month, applyBeforeFrameworkContract }) => {
              const { applyMonthlyPriceBeforeFrameworkDiscount, currentValue } =
                getPromotionCalculationData(acc, applyBeforeFrameworkContract);
              let priceReducedByPercent = calculateReducedByPercentageValue(
                currentValue,
                value
              );

              if (applyMonthlyPriceBeforeFrameworkDiscount) {
                priceReducedByPercent = calculateReducedByPercentageValue(
                  priceReducedByPercent,
                  this.frameworkDiscountPercentageValue
                );
              }

              return monthlyIntervalItem.month === month &&
                acc > priceReducedByPercent
                ? priceReducedByPercent
                : acc;
            },
            monthlyIntervalItem.value
          );

          return {
            ...monthlyIntervalItem,
            value: updatedPrice,
          };
        }
      );
    }

    if (monthlyPromotions.length) {
      const monthlyPromotionsIntervalItems = monthlyPromotions
        .map((promotion) =>
          ProductMonthlyPrices.createMonthlyIntervalItemsArr(
            promotion.discount.from,
            promotion.discount.to,
            promotion.discount.value
          )
        )
        .flat();
      //  Update price
      this.baseMonthlyIntervalItems = this.baseMonthlyIntervalItems.map(
        (monthlyIntervalItem) => {
          const updatedPrice = monthlyPromotionsIntervalItems.reduce(
            (acc, { value, month }) =>
              monthlyIntervalItem.month === month && acc > value ? value : acc,
            monthlyIntervalItem.value
          );

          return {
            ...monthlyIntervalItem,
            value: updatedPrice,
          };
        }
      );
    }

    if (monthlyDiscountPromotions.length) {
      const monthlyDiscountPromotionsIntervalItems = monthlyDiscountPromotions
        .map((promotion) =>
          ProductMonthlyPrices.createMonthlyIntervalItemsArr(
            promotion.discount.from,
            promotion.discount.to,
            promotion.discount.value,
            "applyBeforeFrameworkContract" in promotion &&
              promotion.applyBeforeFrameworkContract
          )
        )
        .flat();

      //  Update price
      this.baseMonthlyIntervalItems = this.baseMonthlyIntervalItems.map(
        (monthlyIntervalItem) => {
          const updatedPrice = monthlyDiscountPromotionsIntervalItems.reduce(
            (acc, { value, month, applyBeforeFrameworkContract }) => {
              const { applyMonthlyPriceBeforeFrameworkDiscount, currentValue } =
                getPromotionCalculationData(acc, applyBeforeFrameworkContract);
              let reducedValue =
                currentValue - value < 0 ? 0 : currentValue - value;

              if (
                reducedValue > 0 &&
                applyMonthlyPriceBeforeFrameworkDiscount
              ) {
                reducedValue = calculateReducedByPercentageValue(
                  reducedValue,
                  this.frameworkDiscountPercentageValue
                );
              }

              return monthlyIntervalItem.month === month ? reducedValue : acc;
            },
            monthlyIntervalItem.value
          );

          return {
            ...monthlyIntervalItem,
            value: updatedPrice,
          };
        }
      );
    }

    // Update monthly interval prices with cart item quantity
    if (this.cartItemQuantity > 1) {
      this.baseMonthlyIntervalItems = this.baseMonthlyIntervalItems.map(
        (monthlyIntervalItem) => ({
          ...monthlyIntervalItem,
          value: monthlyIntervalItem.value * this.cartItemQuantity,
        })
      );
    }

    //  Create price steps
    const monthlyPriceStepsData = ProductMonthlyPrices.createMonthlyPriceSteps(
      this.baseMonthlyIntervalItems
    );

    // Display the regular monthly price if whole contract period price was affected by promotions
    if (
      monthlyPriceStepsData[monthlyPriceStepsData.length - 1].price !==
        this.monthlyPrice * this.cartItemQuantity &&
      typeof this.originalPrice !== "number"
    ) {
      const interval = this.updatedProductContractPeriod + 1;
      const price = this.monthlyPrice * this.cartItemQuantity;
      monthlyPriceStepsData.push({
        price,
        from: interval,
        description: ProductMonthlyPrices.priceIntervalText(interval, price),
      });
    }

    if (typeof this.originalPrice === "number") {
      const interval = this.updatedProductContractPeriod + 1;
      const price = this.originalPrice * this.cartItemQuantity;
      monthlyPriceStepsData.push({
        price,
        from: interval,
        description: ProductMonthlyPrices.priceIntervalText(interval, price),
      });
    }
    this.monthlyPriceSteps = monthlyPriceStepsData.map(
      ({ description, ...priceStepData }) => priceStepData
    );
    this.monthlyPriceStepsDescriptions = monthlyPriceStepsData
      .map(
        (priceStepData) => priceStepData.description
        // We don't need the description of the first step. Because we display it in as a main price in Summary
      )
      .slice(1);
  }

  /**
   * @returns {string} - A string that represents the price after the contract period with formatted text.
   */

  static priceIntervalText(interval: number, price: number) {
    return `Ab dem ${interval}. Monat ${formatNumberWithTrimTrailingZeros(
      price
    )} €`;
  }

  static priceAverageText(averagePriceValue: number) {
    return `rechnerischer 2-Jahres-Preis ${formatNumberWithTrimTrailingZeros(
      averagePriceValue
    )} <sup>2</sup> €`;
  }
}
