/* eslint-disable no-restricted-globals */
import React from "react";
import { CopyText } from "@dtpk-cc/components";
import { onlyNumber, preventDecimal } from "helpers/FireFoxFix";

import "./price-input.scss";

const numberHandler = (inFloat, number) =>
  inFloat ? Number(number).toFixed(2) : number;

const handleMaxValue = (maxValue, value) =>
  maxValue && value > maxValue ? maxValue : value;

const trimmInputValue = (inFloat, inputValue) => {
  let trimmedInputValue = inputValue.replace("^", "");

  if (trimmedInputValue.search(",") && trimmedInputValue.match(/,\d+/)) {
    trimmedInputValue = numberHandler(
      inFloat,
      trimmedInputValue.replace(",", ".")
    );

    return trimmedInputValue;
  }

  return trimmedInputValue;
};

const PriceInput = ({
  value,
  suffix = "€ mtl.",
  onChange,
  onClick,
  maxValue = null,
  inFloat = true,
  rerender = null,
}) => {
  const displayValue = value === 0 ? "" : value.toString().replace(".", ",");

  const submitInput = (inputValue) => {
    const trimmedInputValue = trimmInputValue(inFloat, inputValue);

    const humandReadableNumber = trimmedInputValue.replace(",", "");

    const valueString = handleMaxValue(maxValue, Number(humandReadableNumber));

    if (isNaN(Number(valueString))) return onChange(0);

    return onChange(Number(valueString));
  };

  const handleChange = (inputValue) => {
    const trimmedInputValue = trimmInputValue(inFloat, inputValue);

    if (isNaN(Number(trimmedInputValue))) {
      return onChange(trimmedInputValue);
    }

    const valueString = handleMaxValue(maxValue, Number(trimmedInputValue));

    return onChange(Number(valueString));
  };

  return (
    <>
      <input
        key={rerender}
        onClick={onClick}
        className="price-input"
        value={displayValue}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
        onBlur={(e) => submitInput(e.target.value)}
        step={inFloat ? 0.01 : 1}
        onKeyPress={(event) => {
          onlyNumber(event);
          if (!inFloat) {
            preventDecimal(event);
          }
        }}
      />
      <CopyText
        variants={[CopyText.Variant.highlight, CopyText.Variant.magenta]}
        tag="span"
        customClass="price-input__copy"
      >
        {suffix}
      </CopyText>
    </>
  );
};

export default PriceInput;
